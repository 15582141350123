import React, {useEffect} from "react";
import '../../styles/desktop.css';

import imagesData from '../data/imagesDataGroupeB';
import Modal from "../Modal";

import carnetSmall from "../../images/desktop_small/carnet.png";
import lettre from "../../images/multi/groupe2/desktop_small/lettre.png";
import loupe from "../../images/desktop_small/loupe.png";
import notice from "../../images/desktop_small/notice.png";
import pc from "../../images/desktop_small/pc.png";
// import plansSmall from "../../images/desktop_small/plans_small.png"

function Desktop(props) {    
    useEffect(() => {
    })

    return (
        <div className={`vigilants-desktop`}>
            <div className="container">
                <div className="icones">
                    <div className="noticeBloc bloc">
                        <Modal imagesData={imagesData} alt="notice Guizmo" src={notice} attributes="noticeIcon icon" category="notice"/>
                    </div>
                    <div className="lettreBloc bloc">
                        <Modal imagesData={imagesData} alt="lettre a Renée" src={lettre} attributes="lettreIcon icon" category="lettre"/>
                    </div>
                    <div className="loupeBloc bloc">
                        <Modal imagesData={imagesData} alt="Carte Postale" src={loupe} attributes="loupeIcon icon" category="carte"/>
                    </div>
                    <div className="pcBloc bloc">
                        <Modal imagesData={imagesData} alt="Mail Vigilants" src={pc} attributes="pcIcon icon" category="pc"/>
                    </div>
                    <div className={`carnetBloc bloc ${props.getCarnetPlansStatus()}`}>
                        <Modal imagesData={imagesData} alt="carnet" src={carnetSmall} attributes={`carnetIcon icon`} category="carnet"/>
                    </div>
                    {/* <div className={`plansBloc bloc ${props.getCarnetPlansStatus()}`}>
                        <Modal imagesData={imagesData} alt="plans" src={plansSmall} attributes={`plansIcon icon`} category="plans"/>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Desktop;