import React from "react";
import ChatBot from "react-simple-chatbot";

import "./fakebot.css";
import 'reactjs-popup/dist/index.css';

import KheopsValidation from './validators/KheopsValidation';

class Enigme3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {essais: 1, reponseUtilisateur: "", essais_coffre: 1};

    this.updateEssais = this.updateEssais.bind(this);
    this.resetEssais = this.resetEssais.bind(this);
    this.getEssais = this.getEssais.bind(this);
  }

  updateEssais() {
    this.setState({essais_coffre: this.state.essais_coffre + 1});
  }

  resetEssais() {
    this.setState({essais_coffre: 1});
  }

  getEssais() {
    return(this.state.essais_coffre);
  }

  componentDidMount() {
    this.props.updateCookie(4);
    this.props.displayCarnetPlans();
    const desktopClass = document.querySelector('.vigilants-desktop');
    desktopClass.classList.add("egypte");
  }

  render() {
    const data = [
        {
          id: "arrivee",
          message: "Je suis bien arrivé à la ville de Gizeh ! Je me dirige vers les site archéologiques de Gizeh.",
          trigger: "chaleur"
        },
        {
          id: "chaleur",
          message: "D’ailleurs c’est très joli mais qu’est-ce qu’il fait chaud ! J'espère que mes microprocesseurs ne vont pas fondre…",
          trigger: "temperature"
        },
        {
          id: "temperature",
          message: "Mon thermomètre affiche 48°C 😰",
          trigger: "r_temperature"
        },
        {
          id: "r_temperature",
          options: [
            { value: 1, label: 'Que vois-tu ?', trigger: 'decouverte_gizeh' },
            // { value: 2, label: 'Y a-t-il quelque chose de particulier ?', trigger: 'decouverte_gizeh' },
          ],
        },
        {
          id: "decouverte_gizeh",
          message: "C’est assez grand, je vois plein de choses.. Mon scanner identifie 3 pyramides. Même le Sphinx de Gizeh ! Nous n’avons malheureusement pas le temps d’une visite touristique 😅 ",
          trigger: "destination"
        },
        {
          id: "destination",
          message: "Où dois-je me rendre ?",
          trigger: "kheops"
        },
        {
          id: 'kheops',
          user: true,
          placeholder: "Indiquez-moi notre prochaine destination !",
          trigger: "validation_reponse"
        },
        {
          id: 'validation_reponse',
          component: <KheopsValidation getEssais={this.getEssais} updateEssais={this.updateEssais} resetEssais={this.resetEssais} dontCountEssais={this.dontCountEssais} />,
          asMessage: true,
          waitAction: true,
          delay: 100,
          trigger: "kheops"
        },
        {
          id: 'dest_mauvais1',
          message: "Etes-vous sûrs que ce soit le bon lieu Vigilants ?",
          trigger: "dest_mauvais2"
        },
        {
          id: 'dest_mauvais2',
          message: "L’analyse de l’environnement n’induit en rien de quelconques traces qui pourraient nous mener au sceptre… Algos gagne du terrain, il nous faut essayer un autre endroit, j’attends donc vos instructions !",
          trigger: "kheops"
        },
        {
          id: 'dest_mauvais3',
          message: "Rien par ici...",
          trigger: "kheops"
        },
        {
          id: 'dest_mauvais4_indice',
          message: "Peut-être que vous trouverez des indices dans le carnet de l'archéologue ?",
          trigger: "kheops"
        },
    ];
    return (
          <ChatBot steps={data} {...this.props.config}/>
    );
  }
}
export default Enigme3;
