import React from "react";
import ChatBot from "react-simple-chatbot";

import "./fakebot.css";
import 'reactjs-popup/dist/index.css';

import photo_renee from "../../images/robot/photo_renee.png";
import coffre from "../../images/robot/coffre.png";

import ImageInChat from './validators/ImageInChat';
import CoffreValidation from './validators/CoffreValidation';
import RueValidation from './validators/RueValidation';

class Enigme1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {essais: 1, reponseUtilisateur: "", essais_coffre: 1};

    this.updateEssais = this.updateEssais.bind(this);
    this.resetEssais = this.resetEssais.bind(this);
    this.getEssais = this.getEssais.bind(this);
    this.dontCountEssais = this.dontCountEssais.bind(this);
  }

  updateEssais() {
    this.setState({essais_coffre: this.state.essais_coffre + 1});
    console.log("Essai mis à jour : " + this.state.essais_coffre)
  }

  resetEssais() {
    this.setState({essais_coffre: 1});
    console.log("Essais réinitialisés");
  }

  dontCountEssais() {
    if (this.state.essais_coffre > 0){
      this.setState({essais_coffre: this.state.essais_coffre - 1});
    }
    console.log("Essais maintenus");
  }

  getEssais() {
    return(this.state.essais_coffre);
  }

  componentDidMount() {
    this.props.updateCookie(2);
    this.resetEssais();
    const desktopClass = document.querySelector('.vigilants-desktop');
    desktopClass.classList.add("paris");
  }

  render() {
    const data = [
        {
          id: "intro",
          message: "Et bien, pas trop tôt ! Restez en contact, j’essaie...",
          trigger: "intro_suite"
        },
        {
          id: "intro_suite",
          message: "Bravo vigilants, ça matche ! Maintenant le jour, et faites en sorte que je ne croise personne à la date indiquée ! Il n’est pas question de mettre en risque l’organisation !",
          trigger: "choix_date"
        },
        {
          id: "choix_date",
          options: [
            { value: 1, label: '10 juin', trigger: 'mauvaise_date' },
            { value: 2, label: '11 juin', trigger: 'ouverture_portail' },
            { value: 3, label: '20 juin', trigger: 'mauvaise_date' },
          ],
        },
        {
          id: "mauvaise_date",
          message: "Le portail ne s’active pas Vigilants, ce n’est pas la bonne date ! ",
          trigger: "choix_date"
        },
        {
          id: "ouverture_portail",
          message: "J'active le portail ! ",
          trigger: "activation_portail"
        },
        {
          id: "activation_portail",
          message: "Ok, c’est bon. Maintenant je sais que je dois me rendre rue Cyrano de Bergerac, mais j’ai besoin du numéro de la rue. Cette rue n'a que peu changé. N'hésitez pas à aller voir sur place.",
          trigger: "reponse_numero_rue"
        },
        {
          id: 'reponse_numero_rue',
          user: true,
          placeholder: "Indiquez-moi le numéro de rue !",
          trigger: "validation_rue"
        },
        {
          id: 'validation_rue',
          component: <RueValidation getEssais={this.getEssais} updateEssais={this.updateEssais} resetEssais={this.resetEssais} dontCountEssais={this.dontCountEssais} />,
          asMessage: true,
          waitAction: true,
          trigger: "arrivee_egyptologue"
        },
        {
          id: 'mauvaise_rue',
          message: "Le chargement s’est interrompu. Je suis désolé, ce n’est pas le bon numéro. Faites vite !",
          trigger: "reponse_numero_rue"
        },
        {
          id: 'greater_than',
          message: "Il n'y a pas de batiment à ce numéro",
          trigger: "reponse_numero_rue"
        },
        {
          id: 'arrivee_egyptologue',
          message: "Je pénètre dans les lieux. Mon scanner détecte différents objets… Une table, deux chaises, des fauteuils, un gramophone dans un coin, une cuisinière à bois, une pierre à évier, un vaisselier, un lit et une armoire, un bureau et une bibliothèque. Bien reçu ?",
          trigger: "validation_1"
        },
        {
          id: "validation_1",
          options: [
            { value: 1, label: "Oui !", trigger: 'description_piece' },
          ],
        },
        {
          id: 'description_piece',
          message: "Très bien. A côté de la bibliothèque se trouve un coffre fort, fermé. Sur le coffre se trouve une photo dans un cadre. Sur le bureau, un encrier avec des plumes, un coupe-papier. Le lit est fait et la vaisselle semble lavée de peu.",
          trigger: "description_piece2"
        },
        {
          id: 'description_piece2',
          message: "Je me dirige vers le cadre.",
          trigger: "choix_observation"
        },
        {
          id: "choix_observation",
          options: [
            { value: 1, label: 'Ok', trigger: 'cadre' },
          ],
        },
        {
          id: 'cadre',
          message: "Le cadre contient la photo d'une femme d’une trentaine d’années. Je vous l'envoie.",
          trigger: "photo_renee"
        },
        {
          id: 'photo_renee',
          component: <ImageInChat big={photo_renee} small={photo_renee} />,
          asMessage: true,
          trigger: "description_piece3"
        },
        {
          id: 'description_piece3',
          message: "Et maintenant regardons plus précisément le coffre !",
          trigger: "observation_coffre"
        },
        {
          id: "observation_coffre",
          options: [
            { value: 1, label: 'Bonne idée.', trigger: 'coffre' },
          ],
        },
        {
          id: 'coffre',
          message: "Le coffre est fermé. Il y a 5 molettes numérotées de 0 à 99, qui permettent son ouverture. Je vous envoie la photo.",
          trigger: "photo_coffre"
        },
        {
          id: 'photo_coffre',
          component: <ImageInChat big={coffre} small={coffre} />,
          asMessage: true,
          trigger: "coffre2"
        },
        {
          id: 'coffre2',
          message: "J'espère que vous avez une idée de comment l'ouvrir.",
          trigger: "coffre3"
        },
        {
          id: 'coffre3',
          message: "En tout cas dépêchez-vous ! Je ne compte pas m’éterniser ici au cas où quelqu’un arrive ! Ma fonction Smart Technology m’impose quelques émotions pour être plus proche de votre comportement et vous risquez de devoir me rebooter si une décharge émotionnelle trop puissante me faisait perdre mes moyens !",
          trigger: "code_coffre"
        },
        {
          id: 'code_coffre_prompt',
          message: "Essayez de nouveau un code :",
          trigger: "code_coffre"
        },
        {
          id: 'code_coffre',
          user: true,
          placeholder: "Indiquez-moi le code !",
          trigger: "validation_reponse"
        },
        {
          id: 'validation_reponse',
          component: <CoffreValidation getEssais={this.getEssais} updateEssais={this.updateEssais} resetEssais={this.resetEssais} dontCountEssais={this.dontCountEssais} />,
          asMessage: true,
          waitAction: true,
          trigger: "code_coffre"
        },
        {
          id: 'code_mauvais1',
          message: "*essaie le code*",
          delay: 100,
          trigger: "code_mauvais1_suite"
        },
        {
          id: 'code_mauvais1_suite',
          message: "Ah la la, Vigilants, je suis sûr que vous pouvez mieux faire ! Zut, j’ai entendu la porte de l’immeuble s’ouvrir… Vite, trouvez la bonne combinaison ! J’espère que ce ne sont que les voisins !",
          trigger: "code_coffre"
        },
        {
          id: 'code_mauvais2',
          message: "Trop tard, j’entends des pas ! Oh la la, je crois qu’ils arrivent… Je ne veux pas être mis en cage !",
          trigger: "code_mauvais2_suite"
        },
        {
          id: 'code_mauvais2_suite',
          message: "Je file, je reviendrai plus tard !",
          trigger: "code_mauvais2_suite2"
        },
        {
          id: 'code_mauvais2_suite2',
          message: "*.............*",
          delay: 13000,
          trigger: "code_mauvais2_suite3"
        },
        {
          id: 'code_mauvais2_suite3',
          message: "Ok je suis de retour ! Retentez le code mais faites attention cette fois !",
          trigger: "code_coffre"
        },
        {
          id: 'code_mauvais4_indice',
          message: "Le code ne semble toujours pas bon...",
          trigger: "code_coffre_prompt"
        },
        {
          id: 'code_mauvais_cadre',
          message: "Le cadre représente la photo de sa femme, Renée. Renée... Peut-être y a-t-il un rapport avec son prénom ? J'attends vos instructions pour le code à tester.",
          trigger: "code_coffre"
        },
      ];

    return (
          <ChatBot steps={data} {...this.props.config}/>
    );
  }
}
export default Enigme1;
