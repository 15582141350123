import { BrowserRouter as Router } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import CookieConsent from "react-cookie-consent";
import Jeu from "./components/Jeu";

import "./components/enigmes/fakebot.css";

function App() {
  const trackingId = "G-QYSZEEEZ34";
  const history = createBrowserHistory();

  ReactGA.initialize(trackingId);
  ReactGA.set({
    // any data that is relevant to the user session
    // that you would like to track with google analytics
  })
  history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname)
  }
);
  return (
    < Router className="App">
        <Jeu />
        <CookieConsent
          location="bottom"
          buttonText="Je comprends"
          cookieName="userHasAgreed"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          Ce jeu utilise des cookies pour fonctionner correctement.{" "}
        </CookieConsent>
    </Router>
  );
}

export default App;