import React, {Component} from "react";

class AnneeValidation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            trigger: false,
        };
    }

    componentDidMount() {
        this.props.updateEssais();
    }

    checkCode() {
        const { steps } = this.props;
        const code = steps.date_annee_sceptre.value.replace(/\s+/g, '');
        const nombre_essais = this.props.getEssais();
        console.log("Nombre Essais " + nombre_essais);
        if (isNaN(code)) {
            return(<span>Le code doit être constitué de chiffres uniquement !<br /> <button className="bouton-validation" onClick={() => {this.props.triggerNextStep({trigger: "date_annee_sceptre"}); this.props.dontCountEssais()}}>Continuer</button></span>);
        }
        if (code === "-2600"){
            window.location.replace("/-2600");
        }
        else {
            if (nombre_essais === 2) {
                return(<button className="bouton-validation" onClick={() => this.props.triggerNextStep({trigger: "code_mauvais1"})}> Démarrer le voyage temporel </button>)
            }
            else if (nombre_essais === 5) {
                return(<button className="bouton-validation" onClick={() => {this.props.triggerNextStep({trigger: "code_mauvais1_indice"})}}>Démarrer le voyage temporel</button>)
            }
            return (<button className="bouton-validation" onClick={() => this.props.triggerNextStep({trigger: "code_mauvais1"})}> Démarrer le voyage temporel </button>);
        }
    }

    render() {
        return(
            <div>
                {this.checkCode()}
            </div>
        );
    }
};

export default AnneeValidation;