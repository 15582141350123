import React, {Component} from "react";

class GizehValidation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            trigger: true,
        };

        this.triggerToFalse = this.triggerToFalse.bind(this);
    }

    triggerToFalse() {
        this.setState({trigger: false});
    }

    componentDidMount() {
        this.props.updateEssais();
    }

    checkCode() {
        const { steps } = this.props;
        const code = steps.ville.value;
        const destination = code.toLowerCase();
        const nombre_essais = this.props.getEssais();
        if (destination.includes("gizeh")){
            window.location.replace("/arrivee-a-gizeh");
        }
        else {
            if (destination === "reverse_audio") {
                return(<button className="bouton-validation" onClick={() => this.props.triggerNextStep({trigger: "decryptage_audio"})}> Lancer la commande </button>)
            }
            else if (nombre_essais === 2 && this.state.trigger) {
                return(<button className="bouton-validation" onClick={() => {this.props.triggerNextStep({trigger: "dest_mauvais1"})}}> Vérifier la destination </button>)
            }
            else if (nombre_essais === 3 && this.state.trigger) {
                return(<button className="bouton-validation" onClick={() => {this.props.triggerNextStep({trigger: "dest_mauvais2"})}}> Vérifier la destination </button>)
            }
            else if (nombre_essais === 4) {
                return(<button className="bouton-validation" onClick={() => {this.props.triggerNextStep({trigger: "dest_mauvais4_indice"})}}> Vérifier la destination </button>)
            }
            else if (nombre_essais === 5) {
                return(<button className="bouton-validation" onClick={() => {this.props.triggerNextStep({trigger: "dest_mauvais5_indice"})}}> Vérifier la destination </button>)
            }
            else if (nombre_essais > 5) {
                return(<button className="bouton-validation" onClick={() => this.props.triggerNextStep({trigger: "dest_mauvais1"})}> Vérifier la destination </button>)
            }
            return (<button className="bouton-validation" onClick={() => this.props.triggerNextStep({trigger: "dest_mauvais1"})}> Vérifier la destination </button>);
        }
    }

    render() {
        return(
            <div>
                {this.checkCode()}
            </div>
        );
    }
};

export default GizehValidation;