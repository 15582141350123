import React, {Component} from "react";

class CoffreValidation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            trigger: false,
        };
    }

    componentDidMount() {
        this.props.updateEssais();
    }

    checkCode() {
        const { steps } = this.props;
        const code = steps.code_coffre.value.replace(/\s+/g, '');
        const nombre_essais = this.props.getEssais();
        console.log("Nombre Essais " + nombre_essais);
        if (isNaN(code)) {
            return(<span>Le code doit être constitué de chiffres uniquement !<br /> <button className="bouton-validation" onClick={() => {this.props.triggerNextStep({trigger: "code_coffre_prompt"}); this.props.dontCountEssais()}}>Continuer</button></span>);
        }
        if (code === "1851455" || code === "1805140505"){
            window.location.replace("/contenu-coffre");
        }
        else {
            if (nombre_essais === 2) {
                return(<button className="bouton-validation" onClick={() => this.props.triggerNextStep({trigger: "code_mauvais1_suite"})}> Essayer le code </button>)
            }
            else if (nombre_essais === 3) {
                return(<button className="bouton-validation" onClick={() => {this.props.triggerNextStep({trigger: "code_mauvais2"})}}> Essayer le code </button>)
            }
            else if (nombre_essais === 5) {
                return(<span>Le code ne semble toujours pas bon... Vous devriez regarder plus en détails le cadre, je pense qu'il peut vous aider<br /> <button className="bouton-validation" onClick={() => {this.props.triggerNextStep({trigger: "code_coffre_prompt"})}}>Retenter le code</button><button onClick={() => {this.props.triggerNextStep({trigger: "cadre"})}}>Aller voir le cadre</button></span>)
            }
            else if (nombre_essais > 5) {
                return(<button className="bouton-validation" onClick={() => this.props.triggerNextStep({trigger: "code_mauvais4_indice"})}> Essayer le code </button>)
            }
            return (<button className="bouton-validation" onClick={() => this.props.triggerNextStep({trigger: "code_mauvais4_indice"})}> Essayer le code </button>);
        }
    }

    render() {
        return(
            <div>
                {this.checkCode()}
            </div>
        );
    }
};

export default CoffreValidation;