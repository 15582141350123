import React from "react";
import ChatBot from "react-simple-chatbot";
import Cookies from 'universal-cookie';

import "./fakebot.css";
import 'reactjs-popup/dist/index.css';

import schema from "../../images/robot/schema.jpg";
import schemaGroupe1 from "../../images/multi/groupe1/robot/schema.jpg";
import schemaGroupe2 from "../../images/multi/groupe2/robot/schema.jpg";

import ImageInChat from './validators/ImageInChat';

class Enigme6 extends React.Component {
  constructor(props) {
    super(props);
    const cookies = new Cookies();
    this.state = {reponseUtilisateur: "", essais_coffre: 1, solo: cookies.get("solo"), groupeA: cookies.get("groupeA")};

    this.updateEssais = this.updateEssais.bind(this);
    this.resetEssais = this.resetEssais.bind(this);
    this.getEssais = this.getEssais.bind(this);
    this.MultiSchema = this.MultiSchema.bind(this);
  }

  updateEssais() {
    this.setState({essais_coffre: this.state.essais_coffre + 1});
  }

  resetEssais() {
    this.setState({essais_coffre: 1});
  }

  getEssais() {
    return(this.state.essais_coffre);
  }

  componentDidMount() {
    this.props.updateCookie(7);
    this.props.displayCarnetPlans();
    // Display Chrono and launch it
    if (localStorage.getItem('displayTimer') === null || localStorage.getItem('displayTimer') === false) {
      localStorage.setItem('displayTimer', true);
    }
    this.props.startCountdown();
    // Change background
    const desktopClass = document.querySelector('.vigilants-desktop');
    desktopClass.classList.add("interieur");

  }

  MultiSchema() {
    if (this.state.solo === "solo"){
      return(<ImageInChat big={schema} small={schema} />)
    }
    else {
      if (this.state.groupeA === "multiA"){
          return (<ImageInChat big={schemaGroupe1} small={schemaGroupe1} />)
      }
      else {
          return(<ImageInChat big={schemaGroupe2} small={schemaGroupe2} />)
      }
    }
  }

  IntersectionChoix(props) {
    return(
    <div className="rsc-os">
      <ul className="rsc-os-options options-chemins">
        <li className="rsc-os-option">
          <button className="rsc-os-option-element" onClick={() => {props.triggerNextStep({trigger: "bon_chemin"})}}> 1: Tout droit ! </button>
        </li>
        <li className="rsc-os-option">
          <button className="rsc-os-option-element" onClick={() => {props.loseTime(); props.triggerNextStep({trigger: "mauvais_chemin"})}}> 2: À gauche ! </button>
        </li>
        <li className="rsc-os-option">
          <button className="rsc-os-option-element" onClick={() => {props.loseTime(); props.triggerNextStep({trigger: "mauvais_chemin_droite"})}}> 3: À droite ! </button>
        </li>
      </ul>
    </div>)
  }

  IntersectionChoixEquipeA(props) {
    return(
    <div className="rsc-os">
      <ul className="rsc-os-options options-chemins">
        <li className="rsc-os-option">
          <button className="rsc-os-option-element" onClick={() => {props.triggerNextStep({trigger: "bon_chemin"})}}> Direction 1 ! </button>
        </li>
        <li className="rsc-os-option">
          <button className="rsc-os-option-element" onClick={() => {props.loseTime(); props.triggerNextStep({trigger: "mauvais_chemin"})}}> Direction 2 ! </button>
        </li>
        <li className="rsc-os-option">
          <button className="rsc-os-option-element" onClick={() => {props.loseTime(); props.triggerNextStep({trigger: "mauvais_chemin_droite"})}}> Direction 3 ! </button>
        </li>
      </ul>
    </div>)
  }

  render() {
    const data = [
        {
          id: "arrivee",
          message: "J’entends un mécanisme de poulies ! Ça fonctionne ! Bravo Vigilants ! Une porte dérobée s’ouvre…",
          trigger: "entree"
        },
        {
          id: "entree",
          message: "J’emprunte un couloir. J’arrive à une intersection qui comprend 3 chemins. Où dois-je aller ?",
          trigger: "intersection_choix"
        },
        {
          id: "intersection_choix",
          component: (this.state.groupeA === "multiA") ? <this.IntersectionChoixEquipeA loseTime={this.props.loseTime} /> : <this.IntersectionChoix loseTime={this.props.loseTime} />,
          asMessage: true,
          replace: true,
          waitAction: true,
          trigger: "mauvais_chemin"
        },
        {
          id: "mauvais_chemin",
          message: "J'ai parcouru le chemin pour rien, c'était un cul de sac. Nous avons perdu 5 minutes !",
          trigger: "intersection_choix"
        },
        {
          id: "mauvais_chemin_droite",
          message: "Le sol s'est affaissé, je ne peux pas passer par là. Nous avons perdu 5 minutes !",
          trigger: "intersection_choix"
        },
        {
          id: "bon_chemin",
          message: "Très bien, je continue. Un peu alambiqué ce chemin… J'avance sur celui qui me paraît le plus sûr.",
          trigger: "continuer"
        },
        {
          id: "continuer",
          message: "J'arrive à un passage dallé... Mon scanner m’indique que si je mets le pied sur certaines d’entre elles, elles s’affaisseront et je ne sais pas où je vais me retrouver.",
          delay: 4000,
          trigger: "continuer2"
        },
        {
          id: "continuer2",
          message: "En revanche, certaines autres semblent plus solides. J’ai besoin de votre aide pour m’aider à le traverser. Il y a peut-être un ordre à respecter ?",
          delay: 4000,
          trigger: "continuer3"
        },
        {
          id: "continuer3",
          message: "Je vous communique ce que je vois. À l’entrée de ce passage se trouve une inscription. Ça ressemble à des hiéroglyphes mais ça n’a aucune signification… Je vous envoie la photo.",
          delay: 6000,
          trigger: "photo_schema"
        },
        {
          id: "photo_schema",
          component: <this.MultiSchema />,
          asMessage: true,
          delay: 4000,
          trigger: "schema"
        },
        {
          id: "schema",
          message: "Pouvez-vous m'indiquer le chemin à suivre pour ne pas marcher sur les mauvaises dalles ?",
          trigger: "passage_dalles"
        },
        {
          id: 'passage_dalles',
          component: (<iframe src="https://lockee.fr/o/7oI92l3N?nobg&noft&top" height="400" width="350px" frameborder="0" title="enigme_dalles"></iframe>),
          asMessage: true,
          waitAction: true,
          delay: 4000,
          end: true
        },
    ];
    return (
          <ChatBot steps={data} {...this.props.config}/>
    );
  }
}
export default Enigme6;
