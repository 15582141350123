import {useEffect, useState} from 'react';
import "../styles/modal.css";

function ModalContainer({showModal, setShowModal, videoUrl, text}) {
    useEffect(() => {
        document.addEventListener("keydown", closeWithEscape, false);
    
        return () => {
            document.removeEventListener("keydown", closeWithEscape, false);
        };
    });

    function closeWithEscape(e) {
        const keyPressed = e.key;
        if (keyPressed === "Escape")
        {setShowModal(false)}
    }

    return (
        <div>
            {
            showModal 
            ? 
                <div className="modalContainer">
                    <button className="closeButton" onClick={() => setShowModal(false)}>X</button>
                    <iframe width="560" height="315" src={videoUrl} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title={text}></iframe>
                </div> 
            : 
                null
            }
        </div>
    );
} 

function ModalVideo(props) {
    const [showModal, setShowModal] = useState(props.open || false);
    function openModal() {
        setShowModal(prev => !prev);
        // Also change showBegin value to display "begin" button in tutorial
    }

    // THE MODAL COMPONENT MUST RECEIVE IMG ATTRIBUTES TO DISPLAY IT ON DESKTOP, AS WELL AS ALL IMAGES TO BE DISPLAYED IN THE MODAL WHEN CLICKED ON
    return (
        <div>
            {props.videoUrl ? <button onClick={() => {openModal(); props.showBeginButton()}} className={`button + ${props.attributes}`}>{props.text}</button> : null}
            <ModalContainer showModal={showModal} setShowModal={setShowModal} videoUrl={props.videoUrl} text={props.text}/>
        </div>
    )
};

export default ModalVideo;