import {Link} from 'react-router-dom';
import {useEffect} from 'react';

function ImageInChat(props) {

    useEffect(() => {
        // const container = document.querySelector(".chatModalContainer");
        // container.style.color = "#fff";
        // container.classList.remove(".hidden");
    })

    return (
        <div>
            <Link to={props.big} target="_blank">
                <img alt={props.name} src={props.small} className="thumbnail" />
            </Link>
        </div>
    );
}

export default ImageInChat;