import React from "react";
import ChatBot from "react-simple-chatbot";

import "./fakebot.css";
import 'reactjs-popup/dist/index.css';

import selfie from "../../images/robot/selfie_guizmo.png";
import ImageInChat from './validators/ImageInChat';

class Conclusion extends React.Component {
  componentDidMount() {
    this.props.updateCookie(11);
    this.props.displayCarnetPlans();
    const desktopClass = document.querySelector('.vigilants-desktop');
    desktopClass.classList.add("interieur");
    localStorage.setItem('isOnLS', true);
  }

  render() {
    const data = [
        {
          id: "arrivee",
          message: "C’est ça !! Le socle s’ouvre et je viens de récupérer le sceptre ! Bravo Vigilants vous avez réussi votre mission ! 😍",
          trigger: "cacher"
        },
        {
          id: "cacher",
          message: "Je vais le cacher en lieu sûr et dans notre époque actuelle pour être certain qu’Algos ne le retrouve pas !",
          delay: 4000,
          trigger: "fin_mission"
        },
        {
          id: "fin_mission",
          message: "Nous avons fini notre mission Vigilants ! Nous pouvons nous applaudir, enfin je veux dire je vous applaudis car vous avez été très compétents et efficaces pour retrouver ce sceptre !",
          delay: 4000,
          trigger: "fin_mission2"
        },
        {
          id: "fin_mission2",
          message: "Je suis bien triste de vous quitter car nous formions une super équipe 😥 Ah, au fait ! Quand vous vous rendrez au QG de l'organisation, utilisez le code 0411 !",
          trigger: "merci"
        },
        {
          id: "merci",
          message: "Encore merci pour votre aide Vigilants !",
          delay: 4000,
          trigger: "selfie"
        },
        {
          id: 'selfie',
          component: <ImageInChat name="selfie" big={selfie} small={selfie} />,
          asMessage: true,
          trigger: "adieu"
        },
        {
          id: "adieu",
          message: "A bientôt Vigilants ! Moi je ne vous oublierai jamais, je vous le garantis ! 💓",
          trigger: "score",
        },
        {
          id: "score",
          component: <button className="button" onClick={() => {window.location.replace("/score");}}>Voir votre score !</button>,
          asMessage: true,
          end: true,
        },
    ];
    const dataFinAlternative = [
      {
        id: "arrivee",
        message: "C’est ça !! Le socle s’ouvre et je viens de récupérer le sceptre ! Bravo Vigilants vous avez réussi votre mission ! 😍",
        trigger: "cacher"
      },
      {
        id: "cacher",
        message: "Malheureusement, le temps passé dans la pyramide a permis à Algos de nous  repérer… Il vous faut vite couper la communication si nous ne voulons pas qu’il remonte jusqu’à vous. Cela pourrait vous être préjudiciable dans une prochaine mission, et franchement, ce serait dommage qu’on ne travaille plus ensemble !",
        delay: 4000,
        trigger: "fin_mission"
      },
      {
        id: "fin_mission",
        message: "De mon côté, je me dépèche pour aller mettre le sceptre en lieu sûr…",
        delay: 4000,
        trigger: "fin_mission2"
      },
      {
        id: "fin_mission2",
        message: "Surtout, restez bien attentifs aux moindres détails dans votre quotidien, si vous avez le sentiment d’être observés, utilisez le code d’urgence 0312 quand vous vous rendrez au QG de l’organisation !",
        trigger: "merci"
      },
      {
        id: "merci",
        message: "Encore merci pour votre aide Vigilants !",
        delay: 4000,
        trigger: "selfie"
      },
      {
        id: 'selfie',
        component: <ImageInChat name="selfie" big={selfie} small={selfie} />,
        asMessage: true,
        trigger: "adieu"
      },
      {
        id: "adieu",
        message: "A bientôt Vigilants !",
        trigger: "score",
      },
      {
        id: "score",
        component: <button className="button" onClick={() => {window.location.replace("/score");}}>Voir votre score !</button>,
        asMessage: true,
        end: true,
      },
    ];

    var dataToUse;
    if ((localStorage.getItem('minutesLS') > 0) && (localStorage.getItem('secondsLS') > 0)){
      dataToUse = data;
    }
    else {
      dataToUse = dataFinAlternative;
    }

    return (
          <ChatBot steps={dataToUse} {...this.props.config}/>
    );
  }
}
export default Conclusion;
