import {useEffect, useState} from 'react';
import "../styles/modal.css";

import Slideshow from './Slideshow';

function ModalContainer({showModal, setShowModal, imagesData, category}) {
    useEffect(() => {
        document.addEventListener("keydown", closeWithEscape, false);
    
        return () => {
            document.removeEventListener("keydown", closeWithEscape, false);
        };
    });

    function closeWithEscape(e) {
        const keyPressed = e.key;
        if (keyPressed === "Escape")
        {setShowModal(false)}
    }

    return (
        <div>
            {
            showModal 
            ? 
                <div className="modalContainer">
                    <button className="closeButton" onClick={() => setShowModal(false)}>X</button>
                    <Slideshow imagesData={imagesData} category={category}/>
                </div> 
            : 
                null
            }
        </div>
    );
} 

function Modal(props) {
    const [showModal, setShowModal] = useState(props.open || false);
    function openModal() {
        setShowModal(prev => !prev);
    }

    // THE MODAL COMPONENT MUST RECEIVE IMG ATTRIBUTES TO DISPLAY IT ON DESKTOP, AS WELL AS ALL IMAGES TO BE DISPLAYED IN THE MODAL WHEN CLICKED ON
    return (
        <div>
            {props.src ? <img alt={props.alt} src={props.src} onClick={openModal} className={`${props.attributes} imgLink`}/> : null}
            <ModalContainer showModal={showModal} setShowModal={setShowModal} imagesData={props.imagesData} category={props.category} />
        </div>
    )
};

export default Modal;