function AudioInChat(props) {
  return (
      <div>
        <audio controls>
          <source src={props.audio} type="audio/mp3"/>
          <p>Votre navigateur ne prend pas en charge l'audio HTML. Voici un <a href={props.audio}>lien vers le fichier audio</a> pour le télécharger.</p>
        </audio>
        <button className="bouton-validation"><a href={props.audio} download={props.name}>Télécharger</a></button>
      </div>
    );
  }

export default AudioInChat