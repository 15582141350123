import React, {Component} from "react";

class CodeReparationValidation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            trigger: true,
        };

        this.triggerToFalse = this.triggerToFalse.bind(this);
    }

    triggerToFalse() {
        this.setState({trigger: false});
    }

    componentDidMount() {
        this.props.updateEssais();
    }

    checkCode() {
        const { steps } = this.props;
        // On récupère le code et on enlève les espaces
        const code = steps.reparation_module.value.replace(/\s+/g, '');
        // On mets tout en minuscule pour analyse
        const destination_lower = code.toLowerCase();
        // On enlève les accents pour la comparaison
        const destination = destination_lower.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        const nombre_essais = this.props.getEssais();
        if (destination.includes("reboot_trad") ){
            window.location.replace("/proche-du-but");
        }
        else {
            if (nombre_essais === 2 && this.state.trigger) {
                return(<button className="bouton-validation" onClick={() => {this.props.triggerNextStep({trigger: "code_mauvais1"})}}> Analyser </button>)
            }
            else if (destination.includes("rm") ){
                return(<button className="bouton-validation" onClick={() => {this.props.triggerNextStep({trigger: "rm-rf"})}}>Analyser</button>)
            }
            else if (nombre_essais === 5) {
                return(<button className="bouton-validation" onClick={() => {this.props.triggerNextStep({trigger: "code_mauvais1_indice"})}}>Analyser</button>)
            }
            return (<button className="bouton-validation" onClick={() => this.props.triggerNextStep({trigger: "code_mauvais1"})}> Analyser </button>);
        }
    }

    render() {
        return(
            <div>
                {this.checkCode()}
            </div>
        );
    }
};

export default CodeReparationValidation;