import {useEffect, useState} from 'react';
import Cookies from 'universal-cookie';

import "../styles/treestructure.css";

import banniere from "../images/banniere_light.jpg";

const urlsData = [
    {url: "./bienvenue", name: "Le départ", lvl: 1},
    {url: "./egyptologue", name: "Chez l'Egyptologue", lvl: 2},
    {url: "./contenu-coffre", name: "Contenu du coffre", lvl: 3},
    {url: "./arrivee-a-gizeh", name: "Arrivée à Gizeh", lvl: 4},
    {url: "./pyramide-de-kheops", name: "Devant Kheops", lvl: 5},
    {url: "./entree-pyramide", name: "Entrons dans la pyramide", lvl: 6},
    {url: "./chemin-des-dieux", name: "Le chemin des dieux", lvl: 7},
    {url: "./arrivee-dans-la-salle", name: "Arrivée dans la salle", lvl: 8},
    {url: "./proche-du-but", name: "Nous sommes tout proche !", lvl: 9},
    {url: "./-2600", name: "Un dernier retour dans le passé", lvl: 10},
    {url: "./enfin", name: "Bravo !", lvl: 11},
];

function TreeStructure() {
    const [avancee, setAvancee] = useState(0);
    const urlsFilter = urlsData.filter(url => (url.lvl <= avancee))
    const cookies = new Cookies();

    useEffect(() => {
        const desktopClass = document.querySelector('.vigilants-desktop');
        desktopClass.classList.add('hidden');
        const indicesButton = document.querySelector('.dot');
        indicesButton.classList.add('hidden');
        updateAvancee();
    })

    function updateAvancee() {
        setAvancee(cookies.get("currentEnigme"));
    }

    const urlsList = urlsFilter.map((url, index) => {
        return (
            <li key={index.toString()}><a href={url.url}>{url.name}</a></li>
        )
    })

    return (
        <div className="structure">
            <div className="structure-body">
                <img className="structure-banniere" src={banniere} alt="Les Gardiens du sceptre Ouas"/>
                <h1>Sommaire du jeu</h1>
                {cookies.get("currentEnigme") === undefined ?
                <><p>Vous n'avez pas encore commencé le jeu. Vous devez tout d'abord choisir si vous voulez jouer seul, ou à plusieurs en cliquant sur les liens ci-dessous : </p><ul><li><a href="./solo">Je joue seul</a></li><li><a href="./multi">Je joue en groupe</a></li></ul></>
                :
                <><p>Vous trouverez ici les liens vers les différentes énigmes du jeu en fonction de vos avancées :</p><ul>{urlsList}</ul></>
                }
                <a href='./credits'>Crédits du jeu</a>
            </div>
        </div>
    )
}

export default TreeStructure;