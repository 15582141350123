import {useEffect, useState} from 'react';
import Cookies from 'universal-cookie';
import "../styles/tutorial.css";

import ModalVideo from "./ModalVideo";

function TutorialMulti() {
    const [showBegin, setShowBegin] = useState(false);

    useEffect(() => {
        const desktopClass = document.querySelector('.vigilants-desktop');
        desktopClass.classList.add('hidden');
    });

    function showBeginButton() {
        setShowBegin(true);
    }

    const cookies = new Cookies();

    return(
        <div className="desktop-background">
            <div className="container">
                <div className="tutorial-popup">
                    <div className="tutorial-content">
                        <h1>Bonjour, et bienvenue sur "Les gardiens du Sceptre Ouas" !</h1>

                        <p>Jeu d'énigmes en ligne, les gardiens du sceptre Ouas vous transportera dans une aventure que vous devrez traverser en résolvant des énigmes.</p> 
                        <p>Pour avancer dans le jeu, vous allez devoir guider Guizmo, un robot, à travers le temps et l'espace. Pour cela, vous devrez lui donner des indications et vos réponses à l'aide d'une messagerie reliée au robot.</p>
                        <p><strong>Chaque équipe</strong> doit renseigner le champ réponse pour avancer dans le jeu. N'oubliez pas de communiquer les réponses que vous trouverez !</p>
                        <p>Durant toute la durée de votre quête, vous devrez utiliser les ressources du web, et des documents qui vous seront communiqués. 
                        Sur votre bureau, vous trouverez une partie des documents contenant les indices nécessaires à la résolution des énigmes, l’autre partie vous étant communiquée par Guizmo. En cliquant sur chaque élément, vous pourrez les examiner plus en détails.</p>
                        <p><strong>Avant de commencer, regardez la vidéo de briefing en cliquant sur le bouton ci-dessous :</strong></p>
                        <ModalVideo 
                            videoUrl="https://player.vimeo.com/video/506692851"
                            attributes={`video video-briefing`}
                            text="Voir la vidéo de briefing"
                            showBeginButton = {showBeginButton}
                        />
                        <br />
                        {showBegin ? 
                        <>
                            <p>Vous devez ensuite vous partager en deux équipes. Choisissez l'équipe à rejoindre et partez à l'aventure !</p>
                            <p>Bonne mission Vigilants !</p>
                            <button className="button" onClick={() => {cookies.set("groupeA", "multiA", {path: '/'}); cookies.set("solo", "", {path: '/'}); window.location.replace("/bienvenue")}}>Equipe A</button>
                            <button className="buttonEquipeB" onClick={() => {cookies.set("groupeA", "", {path: '/'}); cookies.set("solo", "", {path: '/'}); window.location.replace("/bienvenue")}}>Equipe B</button>
                        </>
                        : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TutorialMulti;