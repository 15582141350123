import {useEffect} from 'react';

import banniere from "../images/banniere_light.jpg";
import "../styles/credits.css";

function Credits () {
    useEffect(() => {
        const desktopClass = document.querySelector('.vigilants-desktop');
        desktopClass.classList.add('hidden');
        const indicesButton = document.querySelector('.dot');
        indicesButton.classList.add('hidden');
    })
    
    return(
        <div className="structure">
            <div className="structure-body">
                <img className="structure-banniere" src={banniere} alt="Les Gardiens du sceptre Ouas"/>
                <h1 className="credits-title">Crédits</h1>
                <ul className="credits-list">
                    <li><strong>Isabelle Négri</strong> : Scénario, Game Design</li>
                    <li><strong>Lisa Ghiringhelli</strong> : Création graphique, Scénario</li>
                    <li><strong>Clara Galley</strong> : Game Design, Scénario</li>
                    <li><strong>Jérémy Bonnaire</strong> : Game Design, Indiçage</li>
                    <li><strong>Geoffrey Négri</strong> : Développement Web</li>
                    <li><strong>Luc Négri</strong> : Commercialisation</li>
                </ul>

                <p>Crédits photos : Pixabay, Unsplash</p>
            </div>
        </div>
    )
}

export default Credits;