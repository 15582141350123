const indicesData = [
    {
        name: "L'année",
        indiceFaible: "L’année que vous cherchez à un rapport avec François Breton de la Roche.",
        indiceMoyen: "Il semblerait que la lettre et le journal contiennent des éléments qui vous aideront ...",
        reponse: "La réponse à l’énigme est 1931. La lettre et le journal sont datés.", 
        id: 1,
    },
    {
        name: "Le jour",
        indiceFaible: "Lisez attentivement la lettre de François.",
        indiceMoyen: "Prêtez attention aux dates de présence de François et de Renée. Ils doivent tous deux être absents si vous voulez agir incognito.",
        reponse: "La réponse à l’énigme est le 11 juin. Dans la lettre que François envoie à Renée il indique qu’il repart en égypte le lendemain, le 11 juin la maison sera donc vide.", 
        id: 2,
    },
    {
        name: "Le numéro de rue",
        indiceFaible: "Le café de l’époque présent sur la carte postale est aujourd’hui fermé.",
        indiceMoyen: "Les rituels de François, associés à un outil d’exploration des rues vont vous permettre de localiser le numéro de sa maison.",
        reponse: "La réponse à l’énigme est 11. Google Street View vous permet de trouver le numéro en comparant avec l’image de la carte postale qui indique la boulangerie à l’angle gauche et le café à l’angle droit.", 
        id: 2,
    },
    {
        name: "Le coffre",
        indiceFaible: "François a choisi son code en tenant compte de ce qu’il aime.",
        indiceMoyen: "François utilisait le chiffrement par substitution dans certains de ses messages",
        reponse: "La réponse à l’énigme est 18 5 14 5 5 il s’agit de la transcription alphanumérique des lettres du prénom de son épouse Renée. A=1, B=2 …", 
        id: 2,
    },
    {
        name: "La destination suivante",
        indiceFaible: `François s’y est rendu pour faire des fouilles`,
        indiceMoyen: "Guizmo possède une fonction qui vous permettra de décoder l’enregistrement sonore.",
        reponse: `La réponse à l’énigme est Gizeh. En utilisant la fonction reverse_audio de Guizmo, vous pouvez décoder l’enregistrement sonore. De plus, la biographie confirme que François y a fait des fouilles jusqu’en 1932.`,
        id: 3,
    },
    {
        name: "L'arrivée sur place",
        indiceFaible: "Seth et Anubis ont tous deux possédé le sceptre. Ils vous mettront sur la voix.",
        indiceMoyen: `“Le guide” de François détient la clé de l’énigme, notamment lorsqu’il dit “Le lieu que vous cherchez est associé à ce que vous cherchez”. Associée aux représentations de Seth et Anubis, la réponse pourra vous être révélée.`,
        reponse: "La réponse à l’énigme est Khéops. L’indice principal est donné par le “guide” qui indique que le lieu recherché est associé au sceptre. Le sceptre ayant appartenu à Seth et Anubis, leur symbole se retrouve sur la tablette présente dans le carnet et qui représente Khéops, avec l’aide de la page de hiéroglyphes.", 
        id: 4,
    },
    {
        name: "La face de la pyramide",
        indiceFaible: "François avait des penchants pour la poésie.",
        indiceMoyen: "L’orientation du complexe de Gizeh et les éléments donnés par François dans son poème vous aideront.",
        reponse: "La réponse à l’énigme est la “face sud”. Une recherche Google vous permet de trouver la position des différents éléments décrits dans les 3 premières rimes du poème.", 
        id: 5,
    },
    {
        name: "Le bloc",
        indiceFaible: "Le poème de François contient des éléments qui vous permettront de vous repérer.",
        indiceMoyen: "Quand vous grimpez 2 niveaux de blocs, vous vous trouvez au  niveau de la ligne des troisièmes blocs. Ce niveau associé à la médiane géométrique d’un triangle vous montrera la voie.",
        reponse: "La réponse à l’énigme est F3. Le poème indique la ligne médiane de la pyramide qui correspond au bloc F, il indique également qu’il faut monter deux niveaux, donc se retrouver devant le troisième, pour trouver l’entrée secrète.",
        id: 5,
    },
    {
        name: "La mélodie",
        indiceFaible: "N’oubliez pas de réinitialiser le module qui vous permet de répondre à cette énigme entre chaque essai. Quoi qu’il en soit, François s’intéresse aux charmeurs de serpents.",
        indiceMoyen: "Les notes musicales s’identifient de manière différente selon les pays. Vous pouvez vous aider d’internet pour trouver le nom des notes si vous n’êtes pas musicien…",
        reponse: "La réponse à l’énigme est A C G F C il faut trouver la correspondance entre les notes écrites dans le carnet et les lettres du cadenas musical.", 
        id: 5,
    },
    {
        name: "Les Dieux",
        indiceFaible: "Chaque dieu de la mythologie égyptienne a un rôle particulier dans l’équilibre naturel.",
        indiceMoyen: "Les mots utilisés dans l’inscription, ainsi que leur ordre, ont un lien avec les rôles des Dieux présents.",
        reponse: "La réponse à l’énigme est Seth, Dieu des Ténèbres, Nout, Déesse du Firmament, Anubis, Dieu de la Mort, Hathor, Déesse de l’Amour, Mâat, Déesse de la Justice, Anuke, Déesse de la Guerre. L’ordre serait 4-2-3-1-6-5, en suivant les indications données dans la phrase.", 
        id: 6,
    },
    {
        name: "Les dalles",
        indiceFaible: "Les hiéroglyphes ont un aspect particulier.",
        indiceMoyen: "Il semble que les traits plus épais désignent une direction.",
        reponse: `Le début se situe dans le coin inférieur gauche. Il vous faut monter ensuite de deux cases pour arriver dans le coin supérieur gauche. Dirigez-vous ensuite vers le centre et descendez une fois. Pour finir il vous faut monter en diagonale vers la droite et monter d’une case afin de terminer en haut à droite. N'oubliez pas de garder le clic de la souris appuyé pendant le processus.`, 
        id: 7,
    },
    {
        name: "Traduction",
        indiceFaible: "Vous avez le moyen de réparer le robot parmi les documents qui vous ont été remis.",
        indiceMoyen: "La notice du robot vous aidera.",
        reponse: "La réponse à l’énigme est reboot_trad. On la trouve dans le guide du robot en page 8.", 
        id: 8,
    },
    {
        name: "Ouvrir la porte",
        indiceFaible: "Prenez le mot au pied de la lettre.",
        indiceMoyen: "L’instruction donnée doit être prise dans son sens premier.",
        reponse: "La réponse à l’énigme est le mot “amis”. Il s’agit du mot qui ouvre la porte comme indiqué dans la phrase.", 
        id: 9,
    },
    {
        name: "Le retour dans le temps",
        indiceFaible: "Le sceptre a déjà été localisé à cette place.",
        indiceMoyen: "La description du sceptre vous donnera une idée de ses apparitions.",
        reponse: "La réponse à l’énigme est - 2600. La réponse se trouve dans la description du sceptre qui se trouve dans le carnet.", 
        id: 9,
    },
    {
        name: "Les couleurs",
        indiceFaible: "Vous trouverez des éléments de cette énigme sur plusieurs documents à disposition. N’hésitez-pas à relire des supports que vous auriez oubliés !",
        indiceMoyen: "Le journal, les hiéroglyphes et le carnet vous aideront dans la résolution de cette énigme.",
        reponse: "La réponse à l’énigme est gris rouge vert jaune bleu, la réponse se trouve sur la page des hiéroglyphes pour avoir la traduction de chaque symbole mais elle se trouve également dans le journal et le carnet pour avoir les couleurs des hiéroglyphes.", 
        id: 10,
    },
];

export default indicesData;