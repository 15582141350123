import React, {Component} from "react";

class CoffreValidation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            trigger: false,
        };
    }

    componentDidMount() {
    }

    checkCode() {
        const { steps } = this.props;
        const code = steps.reponse_numero_rue.value.replace(/\s+/g, '');
        if (isNaN(code)) {
            return(<span>Le code doit être constitué de chiffres uniquement !<br /> <button className="bouton-validation" onClick={() => {this.props.triggerNextStep({trigger: "reponse_numero_rue"}); this.props.dontCountEssais()}}>Continuer</button></span>);
        }
        if (code === "11"){
            return (<button className="bouton-validation" onClick={() => {
            const desktopClass = document.querySelector('.vigilants-desktop');
            desktopClass.classList.remove("paris");
            desktopClass.classList.add("appartement");this.props.triggerNextStep({trigger: "arrivee_egyptologue"})}}> Aller voir à ce numéro de rue </button>);
        }
        else if (code > "15") {
            return (<button className="bouton-validation" onClick={() => this.props.triggerNextStep({trigger: "greater_than"})}> Aller voir à ce numéro de rue </button>);
        }
        else {
            return (<button className="bouton-validation" onClick={() => this.props.triggerNextStep({trigger: "mauvaise_rue"})}> Aller voir à ce numéro de rue </button>);
        }
    }

    render() {
        return(
            <div>
                {this.checkCode()}
            </div>
        );
    }
};

export default CoffreValidation;