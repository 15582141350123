import React, {useEffect} from "react";
import '../../styles/desktop.css';
import Cookies from 'universal-cookie';

import imagesData from '../data/imagesDataGroupeA';
import Modal from "../Modal";

import carnetSmall from "../../images/desktop_small/carnet.png";
import livre from "../../images/desktop_small/livre.png";
import notice from "../../images/desktop_small/notice.png";
import loupe from "../../images/desktop_small/loupe.png";
import plansSmall from "../../images/desktop_small/plans_small.png"
import dossier from "../../images/multi/groupe1/desktop_small/dossier.png"
import pc from "../../images/desktop_small/pc.png";

function Desktop(props) {
    const cookies = new Cookies();

    useEffect(() => {
    })

    return (
        <div className={`vigilants-desktop`}>
            <div className="container">
                <div className="icones">
                    <div className="livreBloc bloc">
                        <Modal imagesData={imagesData} alt="biographie" src={livre} attributes="livreIcon icon" category="biographie"/>
                    </div>
                    <div className="noticeBloc bloc">
                        <Modal imagesData={imagesData} alt="notice Guizmo" src={notice} attributes="noticeIcon icon" category="notice"/>
                    </div>
                    <div className="dossierBloc bloc">
                        {cookies.get("currentEnigme") >= 2 ? <Modal imagesData={imagesData} alt="dossierPart2" src={dossier} attributes="dossierIcon icon" category="dossierPart2"/> : <Modal imagesData={imagesData} alt="dossier" src={dossier} attributes="dossierIcon icon" category="dossier"/>}
                    </div>
                    <div className="loupeBloc bloc">
                        <Modal imagesData={imagesData} alt="Carte Postale" src={loupe} attributes="loupeIcon icon" category="carte"/>
                    </div>
                    <div className="pcBloc bloc">
                        <Modal imagesData={imagesData} alt="mail recap" src={pc} attributes={`pcIcon icon`} category="pc"/>
                    </div>
                    <div className={`carnetBloc bloc ${props.getCarnetPlansStatus()}`}>
                        <Modal imagesData={imagesData} alt="carnet" src={carnetSmall} attributes={`carnetIcon icon`} category="carnet"/>
                    </div>
                    <div className={`plansBloc bloc ${props.getCarnetPlansStatus()}`}>
                        <Modal imagesData={imagesData} alt="plans" src={plansSmall} attributes={`plansIcon icon`} category="plans"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Desktop;