import {useEffect, useState} from 'react';
import Cookies from 'universal-cookie';
import {Accordion, Card} from "react-bootstrap";
import "../styles/indices.css"
import indicesData from "./data/indicesData";

function Indices (props) {
    const [enigme, setEnigme] = useState(0);
    const [showAidesIndices, setShowAidesIndices] = useState(false);
    const [showReponse, setShowReponse] = useState(false);
    const [showIndices, setShowIndices] = useState(false);
    const cookies = new Cookies();
    // eslint-disable-next-line
    const indicesFilter = indicesData.filter(indice => (indice.id == enigme));
    var updateIndicesValue = localStorage.getItem("indicesVus");
    var updateReponsesValue = localStorage.getItem("reponsesVues");

    useEffect(() => {
        // ADD THIS TO HIDE DESKTOP FOR INDICES PAGE
        // const desktopClass = document.querySelector('.vigilants-desktop');
        // desktopClass.classList.add('hidden');
        updateEnigme();
        document.addEventListener("keydown", closeWithEscape, false);
    
        return () => {
            document.removeEventListener("keydown", closeWithEscape, false);
        };
    });

    function closeWithEscape(e) {
        const keyPressed = e.key;
        if (keyPressed === "Escape")
        {setShowIndices(false)}
    }

    function openIndices() {
        setShowIndices(prev => !prev);
    }

    function openReponse() {
        setShowReponse(true);
        updateReponsesValue = localStorage.getItem("reponsesVues");
        updateReponsesValue++;
        localStorage.setItem("reponsesVues", updateReponsesValue);
    }

    function openAidesIndices() {
        setShowAidesIndices(true);
        updateIndicesValue = localStorage.getItem("indicesVus");
        updateIndicesValue++;
        localStorage.setItem("indicesVus", updateIndicesValue);
    }

    function updateEnigme() {
        setEnigme(cookies.get("currentEnigme"));
    }

    const indicesList = indicesFilter.map((indice, index) => {
        return (
            <div key={index.toString()}>
                <h2 className="title">{indice.name}</h2>
                <Accordion >
                    <Card>
                        <Accordion.Toggle className="indices-faibles" as={Card.Header} variant="link" eventKey="0">
                            Aide
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>{indice.indiceFaible}</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle className="indices-moyens" as={Card.Header} variant="link" eventKey="1">
                            Indice
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            {showAidesIndices ? 
                                <Card.Body>{indice.indiceMoyen}</Card.Body>
                                : 
                                    <span>Êtes-vous sûrs de vouloir voir l'indice ?
                                    <br />
                                    <button className="button" onClick={() => openAidesIndices()}>Voir l'indice</button>
                                    </span>
                            }
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle className="indices-reponses" as={Card.Header} variant="link" eventKey="2">
                            Réponse
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                            {showReponse ? 
                                <Card.Body>{indice.reponse}</Card.Body> 
                                : 
                                    <span>Êtes-vous sûrs de vouloir voir la réponse ?
                                    <br />
                                    <button className="button" onClick={() => openReponse()}>Voir la réponse</button>
                                    </span>
                            }
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        );
    });



    return (
        <div>
            <div className="dot indices-bubble" onClick={openIndices}></div>
            {
                showIndices
                ?
                    <div className="indices">
                        <div className="indices-container">
                            <button className="closeButtonIndices" onClick={() => setShowIndices(false)}>X</button>
                            <h1 className="title">Indices</h1>
                            <p>Nous vous incitons à utiliser les indices lorsque vous êtes bloqués et que vous ne savez plus où chercher.</p>
                            <p>L'aide est là pour donner un point de vue différent, et ne vous fera pas perdre de points. L'indice vous mettera sur la voie, et vous coûtera 3pts/100 sur votre score final. Enfin, la réponse parle d'elle même et il vous en coûtera 10pts/100.</p>
                            {indicesList}
                            {/* <button className="maj-button" onClick={updateEnigme}>Mettre à jour les indices</button> */}
                        </div>
                    </div>
                :
                    null
            }
        </div>
    )
}

export default Indices;