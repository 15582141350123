import React from "react";
import ChatBot from "react-simple-chatbot";
import Cookies from 'universal-cookie';

import "./fakebot.css";
import 'reactjs-popup/dist/index.css';

import CodeReparationValidation from './validators/CodeReparationValidation';


class Enigme7 extends React.Component {
  constructor(props) {
    super(props);
    const cookies = new Cookies();
    this.state = {reponseUtilisateur: "", essais_coffre: 1, solo: cookies.get("solo"), groupeA: cookies.get("groupeA")};

    this.updateEssais = this.updateEssais.bind(this);
    this.resetEssais = this.resetEssais.bind(this);
    this.getEssais = this.getEssais.bind(this);
  }

  updateEssais() {
    this.setState({essais_coffre: this.state.essais_coffre + 1});
  }

  resetEssais() {
    this.setState({essais_coffre: 1});
  }

  getEssais() {
    return(this.state.essais_coffre);
  }

  componentDidMount() {
    this.props.updateCookie(8);
    this.props.displayCarnetPlans();
    // Display Chrono and launch it
    if (localStorage.getItem('displayTimer') === null || localStorage.getItem('displayTimer') === false) {
      localStorage.setItem('displayTimer', true);
    }
    this.props.startCountdown();
    // Change background
    const desktopClass = document.querySelector('.vigilants-desktop');
    desktopClass.classList.add("interieur");
  }

  IntersectionChoix(props) {
    return(
    <div className="rsc-os">
      <ul className="rsc-os-options options-chemins">
        <li className="rsc-os-option">
          <button className="rsc-os-option-element" onClick={() => {props.loseTime(); props.triggerNextStep({trigger: "bon_chemin"})}}> 1: Tout droit ! </button>
        </li>
        <li className="rsc-os-option">
          <button className="rsc-os-option-element" onClick={() => {props.loseTime(); props.triggerNextStep({trigger: "mauvais_chemin"})}}> 2: À gauche ! </button>
        </li>
      </ul>
    </div>)
  }

  IntersectionChoixEquipeA(props) {
    return(
    <div className="rsc-os">
      <ul className="rsc-os-options options-chemins">
        <li className="rsc-os-option">
          <button className="rsc-os-option-element" onClick={() => {props.loseTime(); props.triggerNextStep({trigger: "bon_chemin"})}}> Direction 1 ! </button>
        </li>
        <li className="rsc-os-option">
          <button className="rsc-os-option-element" onClick={() => {props.loseTime(); props.triggerNextStep({trigger: "mauvais_chemin"})}}> Direction 2 ! </button>
        </li>
      </ul>
    </div>)
  }

  render() {
    const data = [
        {
          id: "arrivee",
          message: "Ok j’essaie… Ouf ! Ce passage est derrière moi. Je vous avoue que j’ai transpiré des durites !",
          trigger: "entree"
        },
        {
          id: "entree",
          message: "Heureusement vous êtes une équipe de choc ! J’aurais sûrement fini en pièces détachées si j’étais tombé ! 😅",
          trigger: "dernier_choix"
        },
        {
          id: "dernier_choix",
          message: "Je continue ma route… Encore une intersection... Quelle direction dois-je prendre ?",
          trigger: "intersection_choix"
        },
        {
          id: "intersection_choix",
          component: (this.state.groupeA === "multiA") ? <this.IntersectionChoixEquipeA loseTime={this.props.loseTime} /> : <this.IntersectionChoix loseTime={this.props.loseTime} />,
          asMessage: true,
          replace: true,
          waitAction: true,
        },
        {
          id: "mauvais_chemin",
          message: "J'ai emprunté ce chemin mais mes scanners détectent des pièges. Je fais demi-tour, tant pis pour le temps perdu.",
          trigger: "intersection_choix"
        },
        {
          id: "bon_chemin",
          message: "Ok j’essaie. C’est le bon chemin !",
          trigger: "continuer"
        },
        {
          id: "continuer",
          message: "Je suis devant la porte de la salle où se trouve ce que vous cherchez je pense. La dernière ! Il y a encore une inscription au-dessus, heureusement que je suis un robot à la pointe de la technologie et que j’ai un super système de tradu…",
          trigger: "continuer2"
        },
        {
          id: "continuer2",
          message: "......",
          delay: 7000,
          trigger: "continuer3"
        },
        {
          id: "continuer3",
          message: "Oh non c’est pas vrai !!!! Mon module de traduction est cassé ! Je crains que vous ne soyez obligé(s) de me réparer à distance !",
          trigger: "reparation_module"
        },
        {
          id: 'reparation_module',
          user: true,
          placeholder: "Entrez vos instructions !",
          trigger: "code_reparation"
        },
        {
          id: 'code_reparation',
          component: <CodeReparationValidation getEssais={this.getEssais} updateEssais={this.updateEssais} resetEssais={this.resetEssais} dontCountEssais={this.dontCountEssais} />,
          delay: 100,
          asMessage: true,
          waitAction: true,
          trigger: "reparation_module"
        },
        {
          id: "code_mauvais1",
          message: "Rien ne se passe... Essayez une autre instruction ! Sans ce module nous ne pouvons plus avancer !",
          trigger: "reparation_module"
        },
        {
          id: "code_mauvais1_indice",
          message: "Toujours rien... Je pense que mon manuel devrait pouvoir vous sortir de cette situation !",
          trigger: "reparation_module"
        },
        {
          id: "rm-rf",
          message: "Wooooohhhhh !!! Vigilants, vous souhaitez ma mort ?? Heureusement que mon système de sécurité bloque ce genre de requêtes...",
          trigger: "reparation_module"
        },
    ];
    return (
          <ChatBot steps={data} {...this.props.config}/>
    );
  }
}
export default Enigme7;
