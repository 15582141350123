import React from "react";
import ChatBot from "react-simple-chatbot";

import "./fakebot.css";
import 'reactjs-popup/dist/index.css';

import AnneeValidation from './validators/AnneeValidation';


class Enigme8 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {reponseUtilisateur: "", essais_coffre: 1};

    this.updateEssais = this.updateEssais.bind(this);
    this.resetEssais = this.resetEssais.bind(this);
    this.getEssais = this.getEssais.bind(this);
    this.dontCountEssais = this.dontCountEssais.bind(this);
  }

  updateEssais() {
    this.setState({essais_coffre: this.state.essais_coffre + 1});
  }

  resetEssais() {
    this.setState({essais_coffre: 1});
  }

  dontCountEssais() {
    if (this.state.essais_coffre > 0){
      this.setState({essais_coffre: this.state.essais_coffre - 1});
    }
    console.log("Essais maintenus");
  }

  getEssais() {
    return(this.state.essais_coffre);
  }

  componentDidMount() {
    this.props.updateCookie(9);
    this.props.displayCarnetPlans();
    // Display Chrono and launch it
    if (localStorage.getItem('displayTimer') === null || localStorage.getItem('displayTimer') === false) {
      localStorage.setItem('displayTimer', true);
    }
    this.props.startCountdown();
    // Change background
    const desktopClass = document.querySelector('.vigilants-desktop');
    desktopClass.classList.add("interieur");
  }

  render() {
    const data = [
        {
          id: "arrivee",
          message: "C’est bon je vous remercie Vigilants ! Mon module de traduction fonctionne de nouveau ! Je savais que vous y arriveriez ! 👍",
          trigger: "trad_porte"
        },
        {
          id: "trad_porte",
          message: "Je vais donc pouvoir vous donner la traduction de l’inscription marquée sur la porte! La voici : \"Parler amis et entrez\"",
          trigger: "entree"
        },
        {
          id: 'entree',
          user: true,
          validator: (value) => {
            const reponse = value.toLowerCase();
            const reponse_wt_spaces = reponse.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s+/g, '');
            if (reponse_wt_spaces === "amis") {
              return true;
            }
            return "Rien ne se passe...";
          },
          placeholder: "???",
          trigger: "ouverture_porte"
        },
        {
          id: "ouverture_porte",
          message: "La porte s’ouvre !",
          trigger: "piece_vide"
        },
        {
          id: "piece_vide",
          message: "Je suis entré dans la pièce mais il n’y a rien dedans. C’est pas vrai, elle est complètement vide !!",
          trigger: "piece_vide2"
        },
        {
          id: "piece_vide2",
          message: "Le sceptre n’y est plus ! J’ai beau scanner tous les recoins, il n’y a plus rien… Peut-être des pilleurs de tombe ?",
          trigger: "piece_vide3"
        },
        {
          id: "piece_vide3",
          message: "Nous n’avons pas bien calibré notre année ! Mais peut-être aussi a-t-il disparu depuis des millénaires...",
          trigger: "piece_vide4"
        },
        {
          id: "piece_vide4",
          delay: 3000,
          message: "Ma batterie me permet de réactiver une seule fois le portail si je veux  pouvoir rentrer après, aussi quand êtes-vous sûrs de sa présence dans ces lieux ?",
          trigger: "date_annee_sceptre"
        },
        {
          id: 'date_annee_sceptre',
          user: true,
          placeholder: "Année de la présence du sceptre.",
          trigger: "verif_annee"
        },
        {
          id: 'verif_annee',
          component: <AnneeValidation getEssais={this.getEssais} updateEssais={this.updateEssais} resetEssais={this.resetEssais} dontCountEssais={this.dontCountEssais} />,
          asMessage: true,
          waitAction: true,
          delay: 100,
          trigger: "date_annee_sceptre"
        },
        {
          id: "code_mauvais1",
          message: "Je ne pense pas que cela soit la bonne année Vigilants ! Relisez bien les documents je crois en vous !",
          trigger: "date_annee_sceptre"
        },
        {
          id: "code_mauvais1_indice",
          message: "Quelque chose me dit que vous êtes sur une mauvaise piste... L'égyptologue n'aurait donc laissé aucun indice pour vous mettre sur la voie ?",
          trigger: "date_annee_sceptre"
        },
    ];
    return (
          <ChatBot steps={data} {...this.props.config}/>
    );
  }
}
export default Enigme8;
