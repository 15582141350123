import React from "react";
import ChatBot from "react-simple-chatbot";
import Cookies from 'universal-cookie';

import "./fakebot.css";
import 'reactjs-popup/dist/index.css';

class Enigme5 extends React.Component {
  constructor(props) {
    super(props);
    const cookies = new Cookies();
    this.state = {reponseUtilisateur: "", essais_coffre: 1, solo: cookies.get("solo"), groupeA: cookies.get("groupeA"), mauvaisChoixChemin: false};

    this.updateEssais = this.updateEssais.bind(this);
    this.resetEssais = this.resetEssais.bind(this);
    this.getEssais = this.getEssais.bind(this);
    this.MultiTraduction = this.MultiTraduction.bind(this);
    this.MultiStatues1 = this.MultiStatues1.bind(this);
    this.MultiStatues2 = this.MultiStatues2.bind(this);
  }

  updateEssais() {
    this.setState({essais_coffre: this.state.essais_coffre + 1});
  }

  resetEssais() {
    this.setState({essais_coffre: 1});
  }

  getEssais() {
    return(this.state.essais_coffre);
  }

  componentDidMount() {
    this.props.updateCookie(6);
    this.props.displayCarnetPlans();
    // this.props.startCountdown();
    const desktopClass = document.querySelector('.vigilants-desktop');
    desktopClass.classList.add("pyramide");
  }

  // DIFFERENTS COMPONENT EN FONCTION DU MODE SELECTIONNE
  MultiTraduction() {
    if (this.state.solo === "solo"){
      return("Je vous envoie la traduction : “L’Amour luit au Firmament, et ni la Mort, ni les Ténèbres, ni la Guerre ne viendront interrompre la Justice de ce moment divin.”")
    }
    else {
      if (this.state.groupeA === "multiA"){
          return ("Je vous envoie la traduction : “L’Amour luit au Firmament, et ni la Mort, ni les Ténèbres, ni la Guerre ne viendront interrompre la Justice de ce moment divin.”")
      }
      else {
          return("J'ai effectué la traduction. L'autre équipe pourra vous donner plus d'informations.")
      }
    }
  }
  MultiStatues1() {
    if (this.state.solo === "solo"){
      return("Mon traducteur lit sous chaque statue, de gauche à droite :")
    }
    else {
      if (this.state.groupeA === "multiA"){
          return ("J'ai l'impression qu'il ya un message sous chacune des statues.")
      }
      else {
          return("Mon traducteur lit sous chaque statue, de gauche à droite :")
      }
    }
  }
  MultiStatues2() {
    if (this.state.solo === "solo"){
      return(<ol><li>Seth</li><li>Nout</li><li>Anubis</li><li>Hathor</li><li>Mâat</li><li>Anuke</li></ol>)
    }
    else {
      if (this.state.groupeA === "multiA"){
          return ("J'ai envoyé la signification des hieroglyphes à l'autre équipe.")
      }
      else {
          return(<ol><li>Seth</li><li>Nout</li><li>Anubis</li><li>Hathor</li><li>Mâat</li><li>Anuke</li></ol>)
      }
    }
  }

  IntersectionChoix(props) {
    return(
    <div className="rsc-os">
      <ul className="rsc-os-options options-chemins">
        <li className="rsc-os-option">
          <button className="rsc-os-option-element" onClick={() => {props.loseTime(); props.triggerNextStep({trigger: "mauvais_chemin"})}}> Direction 1: Tout droit ! </button>
        </li>
        <li className="rsc-os-option">
          <button className="rsc-os-option-element" onClick={() => {props.triggerNextStep({trigger: "bon_chemin"})}}> Direction 2: À gauche ! </button>
        </li>
      </ul>
    </div>)
  }

  IntersectionChoixEquipeA(props) {
    return(
    <div className="rsc-os">
      <ul className="rsc-os-options options-chemins">
        <li className="rsc-os-option">
          <button className="rsc-os-option-element" onClick={() => {props.loseTime(); props.triggerNextStep({trigger: "mauvais_chemin"})}}> Direction 1 ! </button>
        </li>
        <li className="rsc-os-option">
          <button className="rsc-os-option-element" onClick={() => {props.triggerNextStep({trigger: "bon_chemin"})}}> Direction 2 ! </button>
        </li>
      </ul>
    </div>)
  }

  DemarrerChrono(props) {
    return(
    <div className="rsc-os">
      <ul className="rsc-os-options options-chemins">
        <li className="rsc-os-option">
          <button className="rsc-os-option-element" onClick={() => {
            localStorage.setItem('displayTimer', true);
            props.startCountdown();
            props.triggerNextStep({trigger: "fermeture2"})
            }}> Qu'est-ce qu'il se passe ? </button>
        </li>
      </ul>
    </div>)
  }

  render() {
    const data = [
        {
          id: "arrivee",
          message: "Le bloc bouge ! La porte est ouverte ! Je me faufile à l’intérieur je vous envoie un message dès que je suis de l’autre côté !",
          trigger: "entree"
        },
        {
          id: "entree",
          message: "Ok Vigilants, je suis à l’intérieur…",
          trigger: "etat"
        },
        {
          id: "etat",
          message: "Ouf, il fait moins chaud ici. Et vous, ça va ?",
          trigger: "r_etat"
        },
        {
          id: "r_etat",
          options: [
            { value: 1, label: 'Oui tout va pour le mieux !', trigger: 'continuer' },
            { value: 2, label: 'Qu\'est-ce ce que l\'on cherche déjà ?', trigger: 'recap_recherches' },
            { value: 3, label: 'Où en sommes-nous ? Pourquoi pas un petit point...', trigger: 'recap' },
          ],
        },
        {
          id: "continuer",
          message: "Super, on continue !",
          trigger: "suite_entree"
        },
        {
          id: "recap_recherches",
          message: "Ok, je me connecte avec la base afin qu’ils m’envoient les infos. Nous sommes à la recherche du sceptre Ouas, et nous devons au plus vite le récupérer dans la pyramide pour empêcher Algos de s’en emparer.",
          trigger: "suite_entree"
        },
        {
          id: "recap",
          message: "Ok, je me connecte avec la base afin qu’ils m’envoient les infos. Nous avons été récupérer toutes les informations chez l'égyptologue et nous avons brillamment réussi à rentrer dans la pyramide maintenant nous devons traverser cette dernière pour récupérer le fameux sceptre Ouas avant Algos",
          trigger: "suite_entree"
        },
        {
          id: "suite_entree",
          message: "Bien, j’avance.",
          trigger: "fermeture"
        },
        {
          id: "fermeture",
          message: "Ah attendez ! Je reçois une alerte de mon scanner.",
          trigger: "confirmation_fermeture"
        },
        {
          id: "confirmation_fermeture",
          component: <this.DemarrerChrono startCountdown={this.props.startCountdown} />,
          asMessage: true,
          replace: true,
          waitAction: true,
          trigger: "fermeture2"
        },
        {
          id: "fermeture2",
          message: "Les recherches d'Algos s'intensifient ! Si mes calculs sont exacts, il nous reste environ 40 minutes avant qu'il ne nous repère.",
          trigger: "avancee"
        },
        {
          id: "avancee",
          message: "Pas de temps à perdre, j’avance !",
          trigger: "intersection"
        },
        {
          id: "intersection",
          message: "Je vois une intersection. Quelle direction dois-je prendre ?",
          trigger: "intersection_choix"
        },
        {
          id: "intersection_choix",
          component: <this.IntersectionChoix loseTime={this.props.loseTime} />,
          asMessage: true,
          replace: true,
          waitAction: true,
          trigger: "bon_chemin"
        },
        {
          id: "mauvais_chemin",
          message: "Ce chemin est jonché de pièges. Nous avons perdu 5 minutes ! Je pense qu'il faut passer par un autre chemin, je vais donc prendre le chemin de gauche.",
          trigger: "bon_chemin"
        },
        {
          id: "bon_chemin",
          message: "Je me retrouve dans une pièce où il ne semble pas y avoir de sortie…",
          trigger: "presentation_enigme"
        },
        {
          id: "presentation_enigme",
          message: "Il y a une alcôve où se trouvent des statues, au nombre de six, avec des noms en hiéroglyphes. Des hiéroglyphes se trouvent également présents un peu plus loin. Il s’agit d’une phrase.",
          trigger: "traduction_hieroglyphes"
        },
        {
          id: "traduction_hieroglyphes",
          component: <this.MultiTraduction />,
          asMessage: true,
          trigger: "hieroglyphes_choix"
        },
        {
          id: "hieroglyphes_choix",
          options: [
            { value: 1, label: 'Quoi d’autre ?', trigger: 'statues1' },
            { value: 2, label: 'Y a-t-il autre chose ? ', trigger: 'statues1' },
          ],
        },
        {
          id: "statues1",
          component: <this.MultiStatues1 />,
          asMessage: true,
          trigger: "statues2"
        },
        {
          id: "statues2",
          component: <this.MultiStatues2 />,
          asMessage: true,
          trigger: "statues3"
        },
        {
          id: "statues3",
          message: "Mon scanner détecte des anfractuosités sous les statues. On a l’impression que certaines pierres sont mobiles.",
          trigger: "statues4"
        },
        {
          id: "statues4",
          message: "Oui, elles s’enfoncent ! Voyons, voyons…",
          trigger: "statues5"
        },
        {
          id: "statues5",
          message: "Il y a 6 pierres mobiles. Je vous envoie un clavier qui symbolise les pierres.",
          trigger: "validation_statue"
        },
        {
          id: 'validation_statue',
          component: (<iframe src="https://lockee.fr/o/eF5LHrdi?nobg&noft&top" height="400" width="350px" frameborder="0" title="enigme_statues"></iframe>),
          asMessage: true,
          waitAction: true,
          end: true
        },

    ];

    const dataEquipeA = [
      {
        id: "arrivee",
        message: "Le bloc bouge ! La porte est ouverte ! Je me faufile à l’intérieur je vous envoie un message dès que je suis de l’autre côté !",
        trigger: "entree"
      },
      {
        id: "entree",
        message: "Ok Vigilants, je suis à l’intérieur…",
        trigger: "etat"
      },
      {
        id: "etat",
        message: "Ouf, il fait moins chaud ici. Et vous, ça va ?",
        trigger: "r_etat"
      },
      {
        id: "r_etat",
        options: [
          { value: 1, label: 'Oui tout va pour le mieux !', trigger: 'continuer' },
          { value: 2, label: 'Qu\'est-ce ce que l\'on cherche déjà ?', trigger: 'recap_recherches' },
          { value: 3, label: 'Où en sommes-nous ? Pourquoi pas un petit point...', trigger: 'recap' },
        ],
      },
      {
        id: "continuer",
        message: "Super, on continue !",
        trigger: "suite_entree"
      },
      {
        id: "recap_recherches",
        message: "Ok, je me connecte avec la base afin qu’ils m’envoient les infos. Nous sommes à la recherche du sceptre Ouas, et nous devons au plus vite le récupérer dans la pyramide pour empêcher Algos de s’en emparer.",
        trigger: "suite_entree"
      },
      {
        id: "recap",
        message: "Ok, je me connecte avec la base afin qu’ils m’envoient les infos. Nous avons été récupérer toutes les informations chez l'égyptologue et nous avons brillamment réussi à rentrer dans la pyramide maintenant nous devons traverser cette dernière pour récupérer le fameux sceptre Ouas avant Algos",
        trigger: "suite_entree"
      },
      {
        id: "suite_entree",
        message: "Bien, j’avance.",
        trigger: "fermeture"
      },
      {
        id: "fermeture",
        message: "Ah attendez ! Je reçois une alerte de mon scanner.",
        trigger: "confirmation_fermeture"
      },
      {
        id: "confirmation_fermeture",
        component: <this.DemarrerChrono startCountdown={this.props.startCountdown} />,
        asMessage: true,
        replace: true,
        waitAction: true,
        trigger: "fermeture2"
      },
      {
        id: "fermeture2",
        message: "Les recherches d'Algos s'intensifient ! Si mes calculs sont exacts, il nous reste environ 40 minutes avant qu'il ne nous repère.",
        trigger: "avancee"
      },
      {
        id: "avancee",
        message: "Pas de temps à perdre, j’avance !",
        trigger: "intersection"
      },
      {
        id: "intersection",
        message: "Je vois une intersection. Quelle direction dois-je prendre ? Attention, choisissez bien !",
        trigger: "intersection_choix"
      },
      {
        id: "intersection_choix",
        component: <this.IntersectionChoixEquipeA loseTime={this.props.loseTime} />,
        asMessage: true,
        replace: true,
        waitAction: true,
        trigger: "bon_chemin"
      },
      {
        id: "mauvais_chemin",
        message: "Ce chemin est jonché de pièges. Nous avons perdu 5 minutes ! Je pense qu'il faut passer par un autre chemin, je vais donc prendre le chemin de gauche.",
        trigger: "bon_chemin"
      },
      {
        id: "bon_chemin",
        message: "Je me retrouve dans une pièce où il ne semble pas y avoir de sortie…",
        trigger: "presentation_enigme"
      },
      {
        id: "presentation_enigme",
        message: "Il y a une alcôve où se trouvent des statues, au nombre de six, avec des noms en hiéroglyphes. Des hiéroglyphes se trouvent également présents un peu plus loin. Il s’agit d’une phrase.",
        trigger: "traduction_hieroglyphes"
      },
      {
        id: "traduction_hieroglyphes",
        component: <this.MultiTraduction />,
        asMessage: true,
        trigger: "hieroglyphes_choix"
      },
      {
        id: "hieroglyphes_choix",
        options: [
          { value: 1, label: 'Quoi d’autre ?', trigger: 'statues1' },
          { value: 2, label: 'Y a-t-il autre chose ? ', trigger: 'statues1' },
        ],
      },
      {
        id: "statues1",
        component: <this.MultiStatues1 />,
        asMessage: true,
        trigger: "statues2"
      },
      {
        id: "statues2",
        component: <this.MultiStatues2 />,
        asMessage: true,
        trigger: "statues3"
      },
      {
        id: "statues3",
        message: "Mon scanner détecte des anfractuosités sous les statues. On a l’impression que certaines pierres sont mobiles.",
        trigger: "statues4"
      },
      {
        id: "statues4",
        message: "Oui, elles s’enfoncent ! Voyons, voyons…",
        trigger: "statues5"
      },
      {
        id: "statues5",
        message: "Il y a 6 pierres mobiles. Je vous envoie un clavier qui symbolise les pierres.",
        trigger: "validation_statue"
      },
      {
        id: 'validation_statue',
        component: (<iframe src="https://lockee.fr/o/eF5LHrdi?nobg&noft&top" height="400" width="100%" frameborder="0" title="enigme_statues"></iframe>),
        asMessage: true,
        waitAction: true,
        end: true
      },
  ];

    var dataToUse;
    if (this.state.solo === "solo"){
      dataToUse = data;
    }
    else {
      if (this.state.groupeA === "multiA"){
        dataToUse = dataEquipeA;
      }
      else {
        dataToUse = data;  
      }
    }

    return (
          <ChatBot steps={dataToUse} {...this.props.config}/>
    );
  }
}
export default Enigme5;
