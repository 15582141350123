import React from "react";
import ChatBot from "react-simple-chatbot";

import "./fakebot.css";
import 'reactjs-popup/dist/index.css';

import receptacles from "../../images/robot/receptacles.png";

import ImageInChat from './validators/ImageInChat';

class Enigme9 extends React.Component {
  componentDidMount() {
    this.props.updateCookie(10);
    this.props.displayCarnetPlans();
    // Display Chrono and launch it
    if (localStorage.getItem('displayTimer') === null || localStorage.getItem('displayTimer') === false) {
      localStorage.setItem('displayTimer', true);
    }
    this.props.startCountdown();
    // Change background
    const desktopClass = document.querySelector('.vigilants-desktop');
    desktopClass.classList.add("interieur");
  }

  render() {
    const data = [
        {
          id: "arrivee",
          message: "Je suis donc bien arrivé en -2600 et je vous annonce que le Sceptre est bien là ! Le seul problème c’est qu’il est bloqué sur son socle !",
          trigger: "receptacle"
        },
        {
          id: "receptacle",
          message: "Nous devons trouver un moyen de le libérer mais il faut faire vite car je sens qu’Algos est tout proche et qu’il est très furieux !",
          trigger: "choix"
        },
        {
          id: "choix",
          options: [
            { value: 1, label: 'Très bien !', trigger: 'description_receptacle' },
            // { value: 2, label: 'C\'est parti !', trigger: 'description_receptacle' },
          ],
        },
        {
          id: "description_receptacle",
          message: "Je vous envoie une photo de ce que je vois sur le receptacle qui maintient le sceptre enfermé :",
          trigger: "photo_receptacle"
        },
        {
          id: "photo_receptacle",
          component: <ImageInChat big={receptacles} small={receptacles} />,
          asMessage: true,
          trigger: "suite"
        },
        {
          id: "suite",
          message: "Je pense qu'il faut mettre les pierres de couleur dans les emplacements définis.",
          trigger: "suite2"
        },
        {
          id: "suite2",
          message: "Vous avez toute ma confiance Vigilants, j’attends vos instructions !",
          trigger: "code_couleur"
        },
        {
          id: 'code_couleur',
          component: (<iframe src="https://lockee.fr/o/cmHvdrly?nobg&noft&top" height="400" width="350px" frameborder="0" title="receptacle_sceptre"></iframe>),
          asMessage: true,
          waitAction: true,
          end: true,
        },
    ];
    return (
          <ChatBot steps={data} {...this.props.config}/>
    );
  }
}
export default Enigme9;
