import React from "react";
import ChatBot from "react-simple-chatbot";
import Cookies from 'universal-cookie';

import "./fakebot.css";
import 'reactjs-popup/dist/index.css';

import plansSmall from "../../images/desktop_small/plans_small.png";
import hieroglyphesSmall from "../../images/desktop_small/hieroglyphes_small.png";
import plans from "../../images/robot/plans.jpg";
import hieroglyphes from "../../images/robot/hieroglyphes.png";
import enregistrement_audio from "../../images/audio/gu1zmo_record_invert.mp3";
import enregistrement_audio_inverse from "../../images/audio/gu1zmo_record.mp3";
import GizehValidation from './validators/GizehValidation';

import ImageInChat from './validators/ImageInChat';
import AudioInChat from './validators/AudioInChat';


class Enigme2 extends React.Component {
  constructor(props) {
    super(props);
    const cookies = new Cookies()
    this.state = {essais: 1, reponseUtilisateur: "", essais_coffre: 1, solo: cookies.get("solo"), groupeA: cookies.get("groupeA")};

    this.updateEssais = this.updateEssais.bind(this);
    this.resetEssais = this.resetEssais.bind(this);
    this.getEssais = this.getEssais.bind(this);
  }

  updateEssais() {
    this.setState({essais_coffre: this.state.essais_coffre + 1});
  }

  resetEssais() {
    this.setState({essais_coffre: 1});
  }

  getEssais() {
    return(this.state.essais_coffre);
  }

  componentDidMount() {
    this.props.updateCookie(3);
    this.props.displayCarnetPlans();
    const desktopClass = document.querySelector('.vigilants-desktop');
    desktopClass.classList.add("appartement");
  }

  render() {
    const data = [
        {
          id: "verif_code_coffre",
          message: "Attendez j’essaie cette combinaison…",
          trigger: "ouverture_coffre"
        },
        {
          id: "ouverture_coffre",
          message: "Ça s'ouvre ! Bravo Vigilants !",
          trigger: "contenu_coffre"
        },
        {
          id: "contenu_coffre",
          message: "Superrrrr ! J’ai trouvé des plans ! Il y a même d'autres choses… Un carnet. On dirait le carnet de recherche de l'archéologue !",
          trigger: "r_contenu_coffre"
        },
        {
          id: "r_contenu_coffre",
          options: [
            { value: 1, label: 'Super et quoi d’autre ?', trigger: 'decouverte_disque' },
            // { value: 2, label: 'D’autres éléments ?', trigger: 'decouverte_disque' },
          ],
        },
        {
          id: "decouverte_disque",
          message: "Une espèce de disque… Woah, quelle antiquité ! Dessus est inscrit \"L'endroit\".",
          trigger: "ecoute_disque"
        },
        {
          id: "ecoute_disque",
          message: "Je vais l’écouter sur cet appareil que vous appelez “gramophone” et vous envoyer l’enregistrement !",
          trigger: "ecoute_disque_suite"
        },
        {
          id: "ecoute_disque_suite",
          message: "C’est bizarre cet enregistrement ! Ce n’est pas très mélodieux... Je dois disposer des fonctionnalités qui vous permettront de l'analyser.",
          delay: 6000,
          trigger: "envoi_documents"
        },
        {
          id: "envoi_documents",
          message: "Je vous envoie les autres documents, vous les retrouverez également sur votre bureau.",
          delay: 4000,
          trigger: "envoi_plans"
        },
        {
          id: 'envoi_plans',
          message: "Voilà les plans :",
          trigger: "fichier_plans"
        },
        {
          id: 'fichier_plans',
          component: <ImageInChat name="plans" big={plans} small={plansSmall} />,
          asMessage: true,
          trigger: "envoi_carnet"
        },
        {
          id: 'envoi_carnet',
          message: "Vous trouverez le carnet sur votre bureau.",
          delay: 4000,
          trigger: "envoi_hieroglyphes"
        },
        {
          id: 'envoi_hieroglyphes',
          message: "Ah ! Une page vient de tomber du carnet ! Je vous la fait parvenir également :",
          delay: 4000,
          trigger: "fichier_hieroglyphes"
        },
        {
          id: 'fichier_hieroglyphes',
          component: <ImageInChat name="hieroglyphes" big={hieroglyphes} small={hieroglyphesSmall} />,
          asMessage: true,
          trigger: "envoi_enregistrement"
        },
        {
          id: 'envoi_enregistrement',
          message: "Et enfin, voilà l'enregistrement :",
          delay: 4000,
          trigger: "fichier_enregistrement"
        },
        {
          id: 'fichier_enregistrement',
          component: <AudioInChat audio={enregistrement_audio} name="Enregistrement sonore" />,
          asMessage: true,
          trigger: "fin_fouille",
        },
        {
          id: 'fin_fouille',
          message: "Voilà ! Je pense que tout ce qui était d'importance chez l'Egyptologue a été trouvé !",
          trigger: "etape_a_suivre"
        },
        {
          id: 'etape_a_suivre',
          message: "Après avoir exploité toutes ces informations, il va maintenant falloir que vous m'indiquiez quelle sera la prochaine étape de notre voyage.",
          delay: 4000,
          trigger: "demande_direction"
        },
        {
          id: 'demande_direction',
          message: "Indiquez-moi la ville dans laquelle me rendre ou donnez-moi une instruction spécifique :",
          delay: 4000,
          trigger: "ville"
        },
        {
          id: 'ville',
          user: true,
          placeholder: "Indiquez-moi une ville ou une instruction !",
          trigger: "validation_reponse"
        },
        {
          id: 'validation_reponse',
          component: <GizehValidation getEssais={this.getEssais} updateEssais={this.updateEssais} resetEssais={this.resetEssais} dontCountEssais={this.dontCountEssais} />,
          asMessage: true,
          waitAction: true,
          delay: 100,
          trigger: "ville"
        },
        {
          id: 'decryptage_audio',
          message: "J'utilise mon module d'inversion audio ! Voilà ce que j'entends une fois l'enregistrement inversé :",
          trigger: "decryptage_fichier"
        },
        {
          id: 'decryptage_fichier',
          component: <AudioInChat audio={enregistrement_audio_inverse} name="Inversion Audio" />,
          asMessage: true,
          trigger: "ville",
        },
        {
          id: 'dest_mauvais1',
          message: "Je n'ai pas l'impression que ce soit la bonne destination. Réessayez !",
          trigger: "ville"
        },
        {
          id: 'dest_mauvais2',
          message: "Ah oui… oui c’est vrai que c’est sympa ici mais vous êtes sûr que vous m’avez envoyé au bon endroit ?... Ils n’ont pas l’air d’aimer les petits singes ici 😖 Je pense qu’on s’est trompé Vigilants.",
          trigger: "ville"
        },
        {
          id: 'dest_mauvais4_indice',
          message: "Avez-vous cherché du côté de la stéganographie ?",
          trigger: "ville"
        },
        {
          id: 'dest_mauvais5_indice',
          message: "Quelque chose me dit que cet enregistrement audio contient un message mais je n'ai aucun moyen de vérifier... Peut-être trouverez-vous un outil de votre côté pour pouvoir le faire ?",
          trigger: "ville"
        },
    ];

    const dataGroupeA = [
      {
        id: "verif_code_coffre",
        message: "Attendez j’essaie cette combinaison…",
        trigger: "ouverture_coffre"
      },
      {
        id: "ouverture_coffre",
        message: "Ça s'ouvre ! Bravo Vigilants !",
        trigger: "contenu_coffre"
      },
      {
        id: "contenu_coffre",
        message: "Superrrrr ! J’ai trouvé des plans ! Il y a même d'autres choses… Un carnet. On dirait le carnet de recherche de l'archéologue !",
        trigger: "envoi_documents"
      },
      {
        id: "envoi_documents",
        message: "Je vous envoie les documents, vous les retrouverez également sur votre bureau.",
        delay: 4000,
        trigger: "envoi_plans"
      },
      {
        id: 'envoi_plans',
        message: "Voilà les plans :",
        trigger: "fichier_plans"
      },
      {
        id: 'fichier_plans',
        component: <ImageInChat name="plans" big={plans} small={plansSmall} />,
        asMessage: true,
        trigger: "envoi_carnet"
      },
      {
        id: 'envoi_carnet',
        message: "Vous trouverez le carnet sur votre bureau.",
        delay: 4000,
        trigger: "envoi_hieroglyphes"
      },
      {
        id: 'envoi_hieroglyphes',
        message: "Ah ! Une page vient de tomber du carnet ! Je vous l'ajoute dans votre dossier :",
        delay: 4000,
        trigger: "fichier_hieroglyphes"
      },
      {
        id: 'fichier_hieroglyphes',
        component: <ImageInChat name="hieroglyphes" big={hieroglyphes} small={hieroglyphesSmall} />,
        asMessage: true,
        trigger: "envoi_enregistrement"
      },
      {
        id: 'envoi_enregistrement',
        message: "Voilà ! Je pense que tout ce qui était d'importance chez l'Egyptologue a été trouvé !",
        delay: 4000,
        trigger: "etape_a_suivre"
      },
      {
        id: 'etape_a_suivre',
        message: "Après avoir exploité toutes ces informations, il va maintenant falloir que vous m'indiquiez quelle sera la prochaine étape de notre voyage.",
        delay: 4000,
        trigger: "demande_direction"
      },
      {
        id: 'demande_direction',
        message: "Indiquez-moi la ville dans laquelle me rendre ou donnez-moi une instruction spécifique :",
        delay: 4000,
        trigger: "ville"
      },
      {
        id: 'ville',
        user: true,
        placeholder: "Indiquez-moi une ville ou une instruction !",
        trigger: "validation_reponse"
      },
      {
        id: 'validation_reponse',
        component: <GizehValidation getEssais={this.getEssais} updateEssais={this.updateEssais} resetEssais={this.resetEssais} dontCountEssais={this.dontCountEssais} />,
        asMessage: true,
        waitAction: true,
        delay: 100,
        trigger: "ville"
      },
      {
        id: 'decryptage_audio',
        message: "J'utilise mon module d'inversion audio ! Voilà ce que j'entends une fois l'enregistrement inversé :",
        trigger: "decryptage_fichier"
      },
      {
        id: 'decryptage_fichier',
        component: <AudioInChat audio={enregistrement_audio_inverse} name="Inversion Audio" />,
        asMessage: true,
        trigger: "ville",
      },
      {
        id: 'dest_mauvais1',
        message: "Je n'ai pas l'impression que ce soit la bonne destination. Réessayez !",
        trigger: "ville"
      },
      {
        id: 'dest_mauvais2',
        message: "Ah oui… oui c’est vrai que c’est sympa ici mais vous êtes sûr que vous m’avez envoyé au bon endroit ?... Ils n’ont pas l’air d’aimer les petits singes ici 😖 Je pense qu’on s’est trompé Vigilants.",
        trigger: "ville"
      },
      {
        id: 'dest_mauvais4_indice',
        message: "Avez-vous cherché du côté de la stéganographie ?",
        trigger: "ville"
      },
      {
        id: 'dest_mauvais5_indice',
        message: "Quelque chose me dit que l'autre équipe possède des documents qui pourront vous être utiles !",
        trigger: "ville"
      },
    ];

    const dataGroupeB = [
      {
        id: "verif_code_coffre",
        message: "Attendez j’essaie cette combinaison…",
        trigger: "ouverture_coffre"
      },
      {
        id: "ouverture_coffre",
        message: "Ça s'ouvre ! Bravo Vigilants !",
        trigger: "contenu_coffre"
      },
      {
        id: "contenu_coffre",
        message: "Superrrrr ! J’ai trouvé un carnet ! On dirait le carnet de recherche de l'archéologue !",
        trigger: "r_contenu_coffre"
      },
      {
        id: "r_contenu_coffre",
        options: [
          { value: 1, label: 'Super et quoi d’autre ?', trigger: 'decouverte_disque' },
          // { value: 2, label: 'D’autres éléments ?', trigger: 'decouverte_disque' },
        ],
      },
      {
        id: "decouverte_disque",
        message: "Une espèce de disque… Woah, quelle antiquité ! Dessus est inscrit \"L'endroit\".",
        delay: 4000,
        trigger: "ecoute_disque"
      },
      {
        id: "ecoute_disque",
        message: "Je vais l’écouter sur cet appareil que vous appelez “gramophone” et vous envoyer l’enregistrement !",
        trigger: "ecoute_disque_suite"
      },
      {
        id: "ecoute_disque_suite",
        message: "C’est bizarre cet enregistrement ! Ce n’est pas très mélodieux... Je dois disposer des fonctionnalités qui vous permettront de l'analyser.",
        delay: 6000,
        trigger: "prochaine_reponse"
      },
      {
        id: "prochaine_reponse",
        options: [
          { value: 1, label: 'Très bien ! Où peut-on retrouver ça ?', trigger: 'envoi_carnet' },
        ],
      },
      {
        id: 'envoi_carnet',
        message: "Vous trouverez le carnet sur votre bureau.",
        trigger: "envoi_enregistrement"
      },
      {
        id: 'envoi_enregistrement',
        message: "Et enfin, voilà l'enregistrement :",
        delay: 4000,
        trigger: "fichier_enregistrement"
      },
      {
        id: 'fichier_enregistrement',
        component: <AudioInChat audio={enregistrement_audio} name="Enregistrement sonore" />,
        asMessage: true,
        trigger: "fin_fouille",
      },
      {
        id: 'fin_fouille',
        message: "Voilà ! Je pense que tout ce qui était d'importance chez l'Egyptologue a été trouvé !",
        delay: 4000,
        trigger: "etape_a_suivre"
      },
      {
        id: 'etape_a_suivre',
        message: "Après avoir exploité toutes ces informations, il va maintenant falloir que vous m'indiquiez quelle sera la prochaine étape de notre voyage.",
        delay: 6000,
        trigger: "demande_direction"
      },
      {
        id: 'demande_direction',
        message: "Indiquez-moi la ville  dans laquelle me rendre ou une instruction pour la suite de nos recherches :",
        delay: 4000,
        trigger: "ville"
      },
      {
        id: 'ville',
        user: true,
        placeholder: "Indiquez-moi une ville ou une instruction !",
        trigger: "validation_reponse"
      },
      {
        id: 'validation_reponse',
        component: <GizehValidation getEssais={this.getEssais} updateEssais={this.updateEssais} resetEssais={this.resetEssais} dontCountEssais={this.dontCountEssais} />,
        asMessage: true,
        waitAction: true,
        delay: 100,
        trigger: "ville"
      },
      {
        id: 'decryptage_audio',
        message: "J'utilise mon module d'inversion audio ! Voilà ce que j'entends une fois l'enregistrement inversé :",
        trigger: "decryptage_fichier"
      },
      {
        id: 'decryptage_fichier',
        component: <AudioInChat audio={enregistrement_audio_inverse} name="Inversion Audio" />,
        asMessage: true,
        trigger: "ville",
      },
      {
        id: 'dest_mauvais1',
        message: "Je n'ai pas l'impression que ce soit la bonne destination. Réessayez !",
        trigger: "ville"
      },
      {
        id: 'dest_mauvais2',
        message: "Ah oui… oui c’est vrai que c’est sympa ici mais vous êtes sûr que vous m’avez envoyé au bon endroit ?... Ils n’ont pas l’air d’aimer les petits singes ici 😖 Je pense qu’on s’est trompé Vigilants.",
        trigger: "ville"
      },
      {
        id: 'dest_mauvais4_indice',
        message: "Avez-vous cherché du côté de la stéganographie ?",
        trigger: "ville"
      },
      {
        id: 'dest_mauvais5_indice',
        message: "Quelque chose me dit que l'autre équipe possède des documents qui pourront vous êtres utiles !",
        trigger: "ville"
      },
    ];

    var dataToUse;
    if (this.state.solo === "solo"){
      dataToUse = data;
    }
    else {
      if (this.state.groupeA === "multiA"){
        dataToUse = dataGroupeA;
      }
      else {
        dataToUse = dataGroupeB;  
      }
    }

    return (
          <ChatBot steps={dataToUse} {...this.props.config}/>
    );
  }
}
export default Enigme2;
