import {useEffect, useState} from 'react';

import "../styles/score.css";

function ScorePage() {
    const [calculated, setCalculated] = useState(false);
    const [finalScore, setFinalScore] = useState(0);
    const [finalTime, setFinalTime] = useState("");

    useEffect(() => {
        const desktopClass = document.querySelector('.vigilants-desktop');
        desktopClass.classList.add('hidden');
    });

    function returnFinalBarAnim() {
        // display progress bar
        document.querySelector(".progress").classList.remove("hidden");
        // change progress bar value
        const progressBar = document.querySelector(".progress-value");
        var score = calculateScore();
        score = 10*Math.floor(score/10);
        console.log(score);
        console.log(typeof(score));
        progressBar.style.animationName = `load${score}`;        
    }

    function scorePyramide() {
        if ((localStorage.getItem('minutesLS') > 0) && (localStorage.getItem('secondsLS') > 0)){
            return("Oui");
        }
        else {
            return("Non");
        }
    }

    function millisToMinutesAndSeconds(millis) {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        return (minutes + " minutes, " + (seconds < 10 ? '0' : '') + seconds + " secondes");
    }

    function calculateScore() {
        var score = 100;
        if (scorePyramide() === "Non") {
            score -= 15;
        }
        if (localStorage.getItem("totalTime") > 5400000){
            var diff = (localStorage.getItem("totalTime") - 5400000) / 60000;
            score -= diff;
        }
        if (localStorage.getItem("indicesVus") > 0)
            score -= (3 * localStorage.getItem("indicesVus"));
        if (localStorage.getItem("reponsesVues") > 0)
            score -= (10 * localStorage.getItem("reponsesVues"));
        Math.trunc(score);
        return(score);
    }

    function seeScore() {
        if (calculated === false) {
            setCalculated(true);
            setFinalScore(calculateScore());
            setFinalTime(millisToMinutesAndSeconds(localStorage.getItem("totalTime")));
            returnFinalBarAnim();
        }
    }

    return(
        <div className="desktop-background">
            <div className="container">
                <div className="tutorial-popup">
                    <div className="tutorial-content">
                        <h1>Bravo Vigilants !</h1>
                        <iframe 
                            width="100%" 
                            height="300"
                            src="https://www.youtube.com/embed/wypJloWiKhg" 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen
                            title="Debriefing" />
                        <h2>Votre score {localStorage.getItem("teamNameLS")}:</h2>
                        {calculated === true ?
                        <div className="score">
                            <ul className="scoreList">
                                <li>Pyramide réussie en 40 minutes : {scorePyramide()}</li>
                                <li>Nombre d'indices vus : {localStorage.getItem("indicesVus") ? localStorage.getItem("indicesVus") : 0}</li>
                                <li>Nombre de réponses vues : {localStorage.getItem("reponsesVues") ? localStorage.getItem("reponsesVues") : 0}</li>
                                <li>Votre temps : {finalTime}</li>
                            </ul>
                            <h3>Votre score : {finalScore < 0 ? 0 : finalScore} / 100</h3>
                        </div>
                        :
                        <button className="button" onClick={seeScore}>Voir votre score</button>
                        }
                        <div className="progress hidden">
                            <div className="progress-value"></div>
                        </div>
                        <br />
                        <p>L'expérience vous a plu ? Vous avez rencontré des problèmes ? Vous avez des suggestions d'amélioration ?
                        <br />
                        N'hésitez pas à nous contacter à <a href="mailto:contact@loadescape.fr">contact@loadescape.fr</a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScorePage;