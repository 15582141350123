import React, {useState, useEffect} from 'react';
import '../styles/slideshow.css';

function Slideshow(props) {
    const [current, setCurrent] = useState(0);
    const [zoom, setZoom] = useState(false);
    const imagesFilter = props.imagesData.filter(image => image.category === props.category);
    const filteredLength = imagesFilter.length;

    useEffect(() => {
        document.addEventListener("keydown", changeSlide, false);
    
        return () => {
            document.removeEventListener("keydown", changeSlide, false);
        };
    });

    function changeSlide(e) {
        const keyPressed = e.key;
        if (keyPressed === "ArrowRight")
            nextSlide();
        if (keyPressed === "ArrowLeft")
            prevSlide();
    }

    function zoomIn() {
        const activeSlide = document.querySelector(".active");
        const slide = activeSlide.querySelector(".mySlides");
        setZoom(!zoom);
        console.log("Zoom");
        if (zoom === true)
            slide.classList.add("mySlidesZoom");
        else
            slide.classList.remove("mySlidesZoom")
    }

    // Change image depending on index
    function nextSlide() {
        setCurrent(current === filteredLength - 1 ? 0 : current + 1);
    };

    // Change image depending on index
    function prevSlide() {
        setCurrent(current === 0 ? filteredLength - 1 : current - 1);
    };

    // Return null if no data is give
    if(!Array.isArray(props.imagesData) || filteredLength <= 0)
        return null;

    // Display image equal to current index
    const imagesList = imagesFilter.map((image, index) => {
        return(
            <div className={index === current ? "slide active" : "slide"} key={index}>
                {index === current && (<img onClick={zoomIn} className={`mySlides ${image.category === `notice` ? `slideNotice` : ``}`} src={image.src} alt={image.alt} />)}
            </div>
        );
    });

    const slidesDots = imagesFilter.map((image, index) => {
        return(
            <span className={index === current ? "slideDot active" : "slideDot"}></span>
        );
    });

    return (
        <div className="mySlideShow">
            {filteredLength <= 1 ? null : 
            <div>
                <button className="sliderButton leftArrow" onClick={prevSlide}>&#10094;</button>
                <button className="sliderButton rightArrow" onClick={nextSlide}>&#10095;</button>
            </div>
            }
            {imagesList}
            <div className="slide-dots">
                {slidesDots}
            </div>
        </div>
    );
};

export default Slideshow;