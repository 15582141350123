import {useEffect, useState} from 'react';
import Cookies from 'universal-cookie';

import "../styles/tutorial.css";

import ModalVideo from "./ModalVideo";

function Tutorial() {
    const [showBegin, setShowBegin] = useState(false);

    useEffect(() => {
        const desktopClass = document.querySelector('.vigilants-desktop');
        desktopClass.classList.add('hidden');
        cookies.set("solo", "solo", {path: '/'});
        cookies.set("groupeA", "", {path: '/'});
    });

    function showBeginButton() {
        setShowBegin(true);
    }

    const cookies = new Cookies();

    return(
        <div className="desktop-background">
            <div className="container">
                <div className="tutorial-popup">
                    <div className="tutorial-content">
                        <h1>Bonjour, et bienvenue sur "Les gardiens du Sceptre Ouas" !</h1>

                        <p>Jeu d'énigmes en ligne, il peut être joué seul ou à plusieurs. De quoi prendre des notes pourra vous être utile.</p>
                        <p>Pour avancer dans le jeu, vous allez devoir guider Guizmo, un robot, à travers le temps et l'espace. Pour cela, vous devrez lui donner des indications et vos réponses à l'aide d'une messagerie reliée au robot.</p>
                        <p>Durant toute la durée de votre quête, vous devrez utiliser les ressources du web, et des documents qui vous seront communiqués. 
                        Sur votre bureau, vous trouverez une partie des documents contenant les indices nécessaires à la résolution des énigmes, l’autre partie vous étant communiquée par Guizmo. En cliquant sur chaque élément, vous pourrez les examiner plus en détails.</p>
                        <p><strong>Avant de commencer, regardez la vidéo de briefing en cliquant sur le bouton ci-dessous :</strong></p>
                        <ModalVideo 
                            videoUrl="https://www.youtube.com/embed/KxqF5tjojCY"
                            attributes={`video video-briefing`}
                            text="Voir la vidéo de briefing"
                            showBeginButton = {showBeginButton}
                        />
                        <br />
                        {showBegin ? 
                        <>
                            <p>Cliquez ensuite sur le bouton "Commencer" et partez à l'aventure !</p>
                            <p>Bonne mission Vigilants !</p>
                            <button className="button" onClick={() => {
                                window.location.replace("/bienvenue");
                            }}>Commencer le jeu !</button>
                        </>
                        : null}
                        
                        {/* <br />
                        <input type="image" alt="icône PC pour mail" src={pc} className="pcIconTuto" onClick={() => {window.open(`${mail}`,"_blank"); window.location.replace("/bienvenue")}} /> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tutorial;