import {useEffect, React} from "react";
import ChatBot from "react-simple-chatbot";
import Cookies from 'universal-cookie';

import "./fakebot.css";
import 'reactjs-popup/dist/index.css';
import Avatar from "../../images/robot/avatar_guizmo2.png";


function Introduction(props) {
  const cookies = new Cookies();

  useEffect(() => {
  props.updateCookie(1);
  });

  const config_chatbot =
  {
    floating: true,
    opened: false,
    botDelay: 2000,
    userDelay: 1000,
    hideUserAvatar: true,
    hideHeader: false,
    headerTitle: "Guizmo",
    botAvatar: `${Avatar}`,
    floatingIcon: undefined,
    cache: false,
    placeholder: "Saisie indisponible...",
  }

  function validator(value) {
    if (isNaN(value)) {
      return "La valeur doit être un nombre à 4 chiffres.";
    }
    else if (value !== "1931") {
      return "L'année inscrite n'est pas la bonne. Réessayez !";
    }
    else if (value === "1931") {
      window.location.replace("/egyptologue");
    }
    return true;
  }

  const data = [
    {
      id: "intro",
      message: "Bonjour Vigilants ! Je vois que vous êtes fin prêts pour votre mission !",
      trigger: "bonjour"
    },
    {
      id: "bonjour",
      options: [
        { value: 1, label: "Bonjour Guizmo !", trigger: 'question_intro' },
      ],
      placeholder: "Cliquez sur la réponse à donner",
    },
    {
      id: "question_intro",
      message: "Je vous préviens que, ok, je suis un robot, mais vous allez vite découvrir que je suis très éloigné de ces montagnes de technologie sans âme et sans reproche !",
      trigger: "presentation"
    },
    {
      id: "presentation",
      message: "Et en plus, tadaaaaaammmm !",
      trigger: "presentation_suite"
    },
    {
      id: 'presentation_suite',
      message: "Je suis Smart Technology et je voyage dans le temps grâce à mon portail intégré ! Ce qui, soit dit en passant, vous évite de l’emprunter et de revenir avec des os ressemblant à des chamallows !",
      delay: 4000,
      trigger: "presentation_suite_6"
    },
    {
      id: 'presentation_suite_6',
      component: (cookies.get("solo") === "solo") ? <p>Mais dites m’en un peu plus sur vous Vigilants ! Comment vous appelez-vous ?</p> : <p>Mais dites m’en un peu plus sur vous Vigilants ! Quel nom avez-vous choisi pour votre équipe ?</p>,
      asMessage: true,
      trigger: "nom_equipe"
    },
    {
      id: 'nom_equipe',
      user: true,
      validator: (value) => {
        const length = 50;
        value = value.substring(0, length);
        localStorage.setItem("teamNameLS", value);
        return (true);
      },
      placeholder: "Rentrez votre nom (50 caractères max) !",
      trigger: "presentation_suite_7"
    },
    {
      id: 'presentation_suite_7',
      message: "Ah c’est vous ! Et bien je suis honoré et ravi de partager cette mission avec vous !",
      trigger: "validation_4"
    },
    {
      id: "validation_4",
      options: [
        { value: 1, label: "Cool !", trigger: 'presentation_suite_8' },
        // { value: 2, label: "Nous en sommes ravi.e.s également !", trigger: 'presentation_suite_8' },
      ],
    },
    {
      id: 'presentation_suite_8',
      message: "Bon ! Assez palabré ! Je crois que nous sommes pressés par le temps ! Je dois aller Où, et Quand ? Le portail a été pré-programmé par les vigilants. Si vos informations ne sont pas bonnes, celui-ci ne s’activera pas.",
      trigger: "validation_6"
    },
    {
      id: "validation_6",
      options: [
        // { value: 2, label: "Ok.", trigger: 'presentation_suite_10' },
        { value: 1, label: "Compris.", trigger: 'presentation_suite_10' },
      ],
    },
    {
      id: 'presentation_suite_10',
      message: "Alors, je compte sur vous ! Je pense que vous avez de votre côté tous les éléments nécessaires à me donner, et d’ailleurs si vous ne les avez pas, allez les chercher sur le net !",
      trigger: "presentation_suite_11"
    },
    {
      id: 'presentation_suite_11',
      message: "Allez ! Maintenant, j’ai besoin d’une année !",
      trigger: "reponse_annee"
    },
    {
      id: 'reponse_annee',
      user: true,
      placeholder: "Rentrez l'année à laquelle Guizmo doit se rendre !",
      validator,
      end: true,
    },
  ];

  return (
      <ChatBot steps={data} {...config_chatbot}/>
  );
}
export default Introduction;
