import React from "react";
import ChatBot from "react-simple-chatbot";
import Cookies from 'universal-cookie';

import "./fakebot.css";
import 'reactjs-popup/dist/index.css';

import quadrillage from "../../images/robot/quadrillage_pyramide.png";
import quadrillageMultiA from "../../images/multi/groupe1/robot/quadrillage_pyramide_lettres.png";
import quadrillageMultiB from "../../images/multi/groupe2/robot/quadrillage_pyramide_chiffres.png";

import PyramideValidation from './validators/PyramideValidation';
import ImageInChat from './validators/ImageInChat';

class Enigme4 extends React.Component {
  constructor(props) {
    super(props);
    const cookies = new Cookies();
    this.state = {essais: 1, reponseUtilisateur: "", essais_coffre: 1, solo: cookies.get("solo"), groupeA: cookies.get("groupeA")};

    this.updateEssais = this.updateEssais.bind(this);
    this.resetEssais = this.resetEssais.bind(this);
    this.getEssais = this.getEssais.bind(this);
    this.MultiQuadrillage = this.MultiQuadrillage.bind(this);
  }

  updateEssais() {
    this.setState({essais_coffre: this.state.essais_coffre + 1});
  }

  resetEssais() {
    this.setState({essais_coffre: 1});
  }

  getEssais() {
    return(this.state.essais_coffre);
  }

  componentDidMount() {
    this.props.updateCookie(5);
    this.props.displayCarnetPlans();
    const desktopClass = document.querySelector('.vigilants-desktop');
    desktopClass.classList.add("kheops");
  }

  MultiQuadrillage() {
    if (this.state.solo === "solo"){
      return(<ImageInChat big={quadrillage} small={quadrillage} />)
    }
    else {
      if (this.state.groupeA === "multiA"){
          return (<ImageInChat big={quadrillageMultiA} small={quadrillageMultiA} />)
      }
      else {
          return(<ImageInChat big={quadrillageMultiB} small={quadrillageMultiB} />)
      }
    }
  }

  render() {
    const data = [
        {
          id: "arrivee",
          message: "Je suis devant la pyramide de Khéops. Je devine que vous aimeriez sans doute être avec moi Vigilants mais rassurez-vous personne ne m’attend devant la porte avec un jus d’orange et un petit encas... (bon ça aurait sûrement fait griller ma batterie)",
          trigger: "entree"
        },
        {
          id: "entree",
          message: "Il semble que l’entrée principale de la pyramide se fasse depuis sa face Nord… Mais cette entrée est trop empruntée. Vers où dois-je me diriger ?",
          trigger: "facades"
        },
        {
          id: "facades",
          options: [
            { value: 1, label: 'Face Est', trigger: 'mauvaise_face' },
            { value: 2, label: 'Face Ouest', trigger: 'mauvaise_face' },
            { value: 3, label: 'Face Sud', trigger: 'bonne_face' },
          ],
        },
        {
          id: "mauvaise_face",
          message: "Cette face n’est apparemment pas celle que l’on recherche. Rien n’indique qu’une entrée y soit dissimulée. Que des pierres, et mon scanner ne détecte aucun espace vide derrière...",
          trigger: "facades"
        },
        {
          id: "bonne_face",
          message: "Je suis devant la face Sud et je vois une multitude de blocs de pierre. Mon scanner détecte des espaces vides derrière certains.",
          trigger: "photo_pyramide"
        },
        {
          id: "photo_pyramide",
          message: "Je vous envoie une photo avec les coordonnées des blocs.",
          trigger: "envoi_photo"
        },
        {
          id: "photo_pyramide",
          component: <this.MultiQuadrillage />,
          asMessage: true,
          trigger: "blocs"
        },
        {
          id: "blocs",
          message: "Vers quel bloc voulez-vous que j’aille ? Indiquez-moi les coordonnées pour que je sache où avec précision.",
          trigger: "choix_blocs"
        },
        {
          id: 'choix_blocs',
          user: true,
          placeholder: "Indiquez les bonnes coordonnées",
          trigger: "validation_reponse"
        },
        {
          id: 'validation_reponse',
          component: <PyramideValidation getEssais={this.getEssais} updateEssais={this.updateEssais} resetEssais={this.resetEssais} />,
          asMessage: true,
          waitAction: true,
          delay: 100,
          trigger: "blocs"
        },
        {
          id: 'coord_mauvais1',
          message: "Rien derrière ce bloc...",
          trigger: "choix_blocs"
        },
        {
          id: 'coord_mauvais2',
          message: "Ce bloc n’est apparemment pas celui que l’on recherche. Rien n’indique qu’une entrée y soit dissimulée. Que des pierres, et mon scanner ne détecte aucun espace vide derrière...",
          trigger: "choix_blocs"
        },
        {
          id: 'coord_ok',
          message: "Effectivement Vigilants, ce bloc laisse apparaître un vide derrière ! Je vais essayer de l’ouvrir… il m'a l'air plutôt lourd… je ne pense pas réussir à le déplacer 😣",
          trigger: "coord_ok2"
        },
        {
          id: 'coord_ok2',
          message: "Ah attendez j’ai l’impression qu’il y a une espèce de trappe. Souhaitez-vous que je l’explore ?",
          trigger: "arrivee_bloc"
        },
        {
          id: "arrivee_bloc",
          options: [
            { value: 1, label: 'Oui !', trigger: 'ouverture_bloc' },
            { value: 2, label: 'Tu es sûr de ton coup ?', trigger: 'hesitation' },
            { value: 3, label: 'Gaffe aux serpents...', trigger: 'hesitation' },
          ],
        },
        {
          id: 'hesitation',
          message: "Gloups... Vous ne me rassurez pas !",
          trigger: "hesitation_suite"
        },
        {
          id: 'hesitation_suite',
          message: "Bon je tente quand même le coup !",
          trigger: "ouverture_bloc"
        },
        {
          id: 'ouverture_bloc',
          message: "Je l’ouvre ! Derrière se trouvent des pierres disposées l’une à côté de l’autre. Quand je pousse chaque pierre, un son en sort…",
          trigger: "ouverture_bloc2"
        },
        {
          id: 'ouverture_bloc2',
          message: "Peut-être avez-vous une idée de ce que je dois actionner ?",
          trigger: "note_musique"
        },
        {
          id: 'note_musique',
          component: (<iframe src="https://lockee.fr/o/D9YEqhGH?nobg&noft&top" height="400" width="350px" frameborder="0" title="enigme_son"></iframe>),
          asMessage: true,
          waitAction: true,
          end: true
        },

    ];
    return (
          <ChatBot steps={data} {...this.props.config}/>
    );
  }
}
export default Enigme4;
