import carnet1 from "../../images/desktop_big/carnet/carnet1.jpg"
import carnet3 from "../../images/desktop_big/carnet/carnet3.jpg"
import carnet2 from "../../images/desktop_big/carnet/carnet2.jpg"

import livre1 from "../../images/desktop_big/biographie/livre_big_recto.png"
import livre2 from "../../images/desktop_big/biographie/livre_big_verso.png"

import couv from "../../images/desktop_big/notice_robot/couv.png"
import notice1 from "../../images/desktop_big/notice_robot/notice1.jpg"
import notice2 from "../../images/desktop_big/notice_robot/notice2.jpg"
import notice3 from "../../images/desktop_big/notice_robot/notice3.jpg"
import notice4 from "../../images/desktop_big/notice_robot/notice4.jpg"
import notice5 from "../../images/desktop_big/notice_robot/notice5.jpg"
import notice6 from "../../images/desktop_big/notice_robot/notice6.jpg"

import dossier1 from "../../images/desktop_big/dossier/dossier1.jpg";
import dossier2 from "../../images/desktop_big/dossier/dossier2.jpg";
import dossier3 from "../../images/desktop_big/dossier/dossier3.jpg";
import dossier4 from "../../images/desktop_big/dossier/dossier4.jpg";

import plans from "../../images/desktop_big/plans.jpg"
import cartePostale from "../../images/desktop_big/carte_postale.png"
import mail from "../../images/desktop_big/mail_vigilants2.png";


const imagesData = [
    {src: `${carnet1}`, alt: "carnet1", category: "carnet"},
    {src: `${carnet2}`, alt: "carnet2", category: "carnet"},
    {src: `${carnet3}`, alt: "carnet3", category: "carnet"},
    
    {src: `${livre1}`, alt: "Biographie Recto", category: "biographie"},
    {src: `${livre2}`, alt: "Biographie Verso", category: "biographie"},

    {src: `${couv}`, alt: "Couverture Notice Guizmo", category: "notice"},
    {src: `${notice1}`, alt: "Notice Guizmo P1", category: "notice"},
    {src: `${notice2}`, alt: "Notice Guizmo P2", category: "notice"},
    {src: `${notice3}`, alt: "Notice Guizmo P3", category: "notice"},
    {src: `${notice4}`, alt: "Notice Guizmo P4", category: "notice"},
    {src: `${notice5}`, alt: "Notice Guizmo P5", category: "notice"},
    {src: `${notice6}`, alt: "Notice Guizmo P6", category: "notice"},

    {src: `${dossier1}`, alt: "Dossier P1", category: "dossier"},
    {src: `${dossier2}`, alt: "Dossier P2", category: "dossier"},
    {src: `${dossier3}`, alt: "Dossier P3", category: "dossier"},

    {src: `${dossier1}`, alt: "Dossier P1", category: "dossierPart2"},
    {src: `${dossier2}`, alt: "Dossier P2", category: "dossierPart2"},
    {src: `${dossier3}`, alt: "Dossier P3", category: "dossierPart2"},
    {src: `${dossier4}`, alt: "Dossier P4", category: "dossierPart2"},

    {src: `${plans}`, alt: "Plans Pyramide", category: "plans"},
    {src: `${cartePostale}`, alt: "Carte Postale", category: "carte"},
    {src: `${mail}`, alt: "Mail Vigilants", category: "pc"},
];

export default imagesData;