import React, {Component} from 'react';
import { BrowserRouter as Switch, Route } from "react-router-dom";
import Cookies from 'universal-cookie';

import Avatar from "../images/robot/avatar_guizmo2.png";
import Introduction from "./enigmes/Introduction";
import Enigme1 from "./enigmes/Enigme1";
import Enigme2 from "./enigmes/Enigme2";
import Enigme3 from "./enigmes/Enigme3";
import Enigme4 from "./enigmes/Enigme4";
import Enigme5 from "./enigmes/Enigme5";
import Enigme6 from "./enigmes/Enigme6";
import Enigme7 from "./enigmes/Enigme7";
import Enigme8 from "./enigmes/Enigme8";
import Enigme9 from "./enigmes/Enigme9";
import Conclusion from "./enigmes/Conclusion";

class DesktopRoutes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            minutes: localStorage.getItem('minutesLS') || 40,
            seconds: localStorage.getItem('secondsLS') || 0,
            isOn: localStorage.getItem('isOnLS') || false,
            displayTimer: false,
            time: localStorage.getItem('timerTimeLS') || 0,
            start: 0,
            timerIsOn: false,
        }

        this.startCountdown=this.startCountdown.bind(this);
        this.startTimer=this.startTimer.bind(this);
        this.loseTime=this.loseTime.bind(this);
    }

    startCountdown() {
        if(this.state.isOn === false) {
            this.setState({displayTimer: localStorage.getItem('displayTimer')});
            this.myInterval = setInterval(() => {
                const { seconds, minutes } = this.state
                localStorage.setItem('minutesLS', minutes);
                localStorage.setItem('secondsLS', seconds);
                if (seconds > 0) {
                    this.setState(({ seconds }) => ({
                        seconds: seconds - 1
                    }))
                }
                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(this.myInterval)
                    } else {
                        this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59
                        }))
                    }
                }
            }, 1000)
        }
    }

    loseTime() {
        if( this.state.minutes >= 5) {
            this.setState({minutes: this.state.minutes - 5});
        }
        else {
            this.setState({minutes: 0, seconds: 0});
        }
    }

    startTimer() {
        this.setState({
            time: this.state.time,
            start: Date.now() - this.state.time,
            timerIsOn: true,
          })
        this.timer = setInterval(() => {
            this.setState({
                time: Date.now() - this.state.start
            });
            localStorage.setItem('timerTimeLS', this.state.time);
        }, 1000);
    }

    componentDidMount() {
        const cookies = new Cookies();
        this.startTimer();
        // eslint-disable-next-line
        if (cookies.get("currentEnigme") == 11) {
            if (!localStorage.getItem("totalTime"))
                localStorage.setItem("totalTime", this.state.time);
        }
    }

    render() {
        const { minutes, seconds } = this.state;

        const config_chatbot =
        {
            floating: true,
            opened: true,
            botDelay: 2000,
            userDelay: 1000,
            hideUserAvatar: true,
            hideHeader: false,
            headerTitle: "Guizmo",
            botAvatar: `${Avatar}`,
            floatingIcon: undefined,
            cache: false,
            placeholder: "Saisie indisponible...",
        }

        function updateCookie(number) {
            const cookies = new Cookies();
            if (!cookies.get("currentEnigme") || number > cookies.get("currentEnigme"))
                cookies.set("currentEnigme", number, {path: '/'});
        }

        return (
            <div>
                <Switch>
                    <Route path="/bienvenue">
                        <Introduction config={config_chatbot} updateCookie={updateCookie}/>
                    </Route>
                    <Route path="/egyptologue">
                        <Enigme1 config={config_chatbot} updateCookie={updateCookie}/>
                    </Route>
                    <Route path="/contenu-coffre">
                        <Enigme2 config={config_chatbot} updateCookie={updateCookie} displayCarnetPlans={this.props.displayCarnetPlans} />
                    </Route>
                    <Route path="/arrivee-a-gizeh">
                        <Enigme3 config={config_chatbot} updateCookie={updateCookie} displayCarnetPlans={this.props.displayCarnetPlans} />
                    </Route>
                    <Route path="/pyramide-de-kheops">
                        <Enigme4 config={config_chatbot} updateCookie={updateCookie} displayCarnetPlans={this.props.displayCarnetPlans} />
                    </Route>
                    <Route path="/entree-pyramide">
                        <Enigme5 config={config_chatbot} updateCookie={updateCookie} displayCarnetPlans={this.props.displayCarnetPlans} startCountdown={this.startCountdown} loseTime={this.loseTime}/>
                    </Route>
                    <Route path="/chemin-des-dieux">
                        <Enigme6 config={config_chatbot} updateCookie={updateCookie} displayCarnetPlans={this.props.displayCarnetPlans} startCountdown={this.startCountdown} loseTime={this.loseTime} />
                    </Route>
                    <Route path="/arrivee-dans-la-salle">
                        <Enigme7 config={config_chatbot} updateCookie={updateCookie} displayCarnetPlans={this.props.displayCarnetPlans} startCountdown={this.startCountdown} loseTime={this.loseTime} />
                    </Route>
                    <Route path="/proche-du-but">
                        <Enigme8 config={config_chatbot} updateCookie={updateCookie} displayCarnetPlans={this.props.displayCarnetPlans} startCountdown={this.startCountdown} />
                    </Route>
                    <Route path="/-2600">
                        <Enigme9 config={config_chatbot} updateCookie={updateCookie} displayCarnetPlans={this.props.displayCarnetPlans} startCountdown={this.startCountdown} />
                    </Route>
                    <Route path="/enfin">
                        <Conclusion config={config_chatbot} updateCookie={updateCookie} displayCarnetPlans={this.props.displayCarnetPlans} />
                    </Route>
                </Switch>
                <div className="timer">
                    { (minutes <= 0 && seconds <= 0)
                        ? null
                        : 
                        this.state.displayTimer ?
                            <h3 className="countdown">{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</h3>
                            :
                            null
                    }
                </div>
                <div className="algos">
                    { (minutes <= 0 && seconds <= 0)
                        ? <p>Algos nous a repéré !</p> : null
                    }
                </div>
            </div>
        )
    }
}

export default DesktopRoutes;