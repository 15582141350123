import React from 'react';
import { BrowserRouter as Switch, Route } from "react-router-dom";
import Cookies from 'universal-cookie';

import Desktop from "./desktop/Desktop";
import DesktopGroupeA from "./desktop/DesktopGroupeA";
import DesktopGroupeB from "./desktop/DesktopGroupeB";
import Indices from "./Indices";
import DesktopRoutes from "./DesktopRoutes";
import Tutorial from "./Tutorial";
import TutorialMulti from "./TutorialMulti";
import TreeStructure from "./TreeStructure";
import ScorePage from "./ScorePage";
import Credits from "./Credits";


class Jeu extends React.Component {

    constructor(props) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            desktop_elements_hidden: "",
            carnet_plans: "hidden", 
            solo: cookies.get("solo"), 
            groupeA: cookies.get("groupeA"), 
            score: 100,
        }

        this.hideDesktop = this.hideDesktop.bind(this);
        this.getDisplayStatus = this.getDisplayStatus.bind(this);
        this.displayCarnetPlans = this.displayCarnetPlans.bind(this);
        this.getCarnetPlansStatus = this.getCarnetPlansStatus.bind(this);
        this.multiMode = this.multiMode.bind(this);
        this.getSoloStatus = this.getSoloStatus.bind(this);
        this.chooseDesktop = this.chooseDesktop.bind(this);
    }

    componentDidMount() {
        const cookies = new Cookies();
        this.chooseDesktop();
        if (cookies.get("reponsesVues") <= 0){
            cookies.set("reponsesVues", "0", {path: '/'});
        }
    }

    chooseDesktop() {
        if (this.state.solo === "solo"){
            return(<Desktop getCarnetPlansStatus={this.getCarnetPlansStatus} />)
        }
        else {
            if (this.state.groupeA === "multiA"){
                return (<DesktopGroupeA getCarnetPlansStatus={this.getCarnetPlansStatus} />)
            }
            else {
                return(<DesktopGroupeB getCarnetPlansStatus={this.getCarnetPlansStatus} />)
            }
        }
    }

    // ELEMENTS TO KNOW IF WE DISPLAY DESKTOP OR NOT
    hideDesktop() {
        this.setState({desktop_elements_hidden: "hidden"});
    }

    getDisplayStatus() {
        return(this.state.desktop_elements_hidden);
    }

    // ELEMENTS TO KNOW IF WE DISPLAY CARNET AND PLAN ON DESKTOP OR NOT YET
    displayCarnetPlans() {
        this.setState({carnet_plans: ""});
    }

    getCarnetPlansStatus() {
        return(this.state.carnet_plans);
    }

    // ELEMENTS TO CHANGE AND DETERMINE IF IT'S SOLO OR MULTI MODE
    multiMode() {
        this.setState({solo: false});
    }

    getSoloStatus() {
        return(this.state.solo);
    }
    
    render() {
        return(
            <div>
                {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}
                <Switch>
                    <Route path="/" exact>
                        <TreeStructure />
                    </Route>
                    <Route path="/solo">
                        <Tutorial />
                    </Route>
                    <Route path="/multi">
                        <TutorialMulti />
                    </Route>
                    <Route path="/score">
                        <ScorePage />
                    </Route>
                    <Route path="/credits">
                        <Credits />
                    </Route>
                    <Route>
                        <DesktopRoutes displayCarnetPlans={this.displayCarnetPlans}/>
                    </Route>
                </Switch>
                <Indices />
                {this.chooseDesktop()}
            </div> 
        );
    }
}

export default Jeu